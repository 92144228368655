//back to this
import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Form, Input, Select, Switch, Upload, message } from 'antd';
import axios from 'axios';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const { Option } = Select;

const facilityOptions = [
    'Wi-Fi', 'Air Conditioning', 'Television', 'In-room Safe', 'Minibar', 'Coffee/Tea Maker', 'Iron/Ironing Board',
    'Hairdryer', 'Desk/Workspace', 'Room Service', 'Laundry Service', 'Telephone', 'Balcony/Terrace',
    'Private Bathroom', 'Shower', 'Bathtub', 'Bathrobe', 'Slippers', 'Complimentary Toiletries',
    'Soundproof Rooms', 'Heating', 'Wardrobe/Closet', 'Sofa', 'Kitchenette', 'Microwave', 'Refrigerator',
    'Electric Kettle', 'Blackout Curtains', 'Safe', 'Fitness Center Access'
];

const roomViewOptions = [
    'Ocean View', 'Mountain View', 'Garden View', 'City View', 'River View', 'Lake View', 'No View', 'Forest View', 'Bay View', 'Park View'
];

const roomTypeOptions = [
    'Single', 'Double', 'Triple', 'Twin', 'Double/Twin', 'Quad', 'Family', 'Studio', 'Apartment', 'Dorm',
    'Villa', 'Vacation Home', 'Bungalow', 'Tent'
];

const beddingOptions = [
    'King Bed', 'Queen Bed', 'Single Bed', 'Sofa Bed', 'Bunk Bed',
    '1 King Bed and 1 Single Bed', '1 King Bed and 2 Single Beds',
    '1 Queen Bed and 1 Single Bed', '1 Queen Bed and 2 Single Beds',
    '2 Single Beds', '3 Single Beds', '4 Single Beds',
    'King Bed and Sofa Bed', 'Queen Bed and Sofa Bed', 'Single Bed and Sofa Bed',
    '2 King Beds', '2 Queen Beds', 'Input No of Bed Manually'
];

function RoomingListScreen() {
    const [rooms, setRooms] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingRoom, setEditingRoom] = useState(null);
    const [imageFiles, setImageFiles] = useState([]); // For image uploads
    const [manualBeds, setManualBeds] = useState('');

    const [form] = Form.useForm();
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        fetchRooms();
    }, []);

    const fetchRooms = async () => {
        try {
            const response = await axios.get('/api/rooms/getallrooms');
            setRooms(response.data);
        } catch (error) {
            message.error('Failed to fetch rooms');
        }
    };

    const handleEdit = (room) => {
        setEditingRoom(room);
        setIsModalVisible(true);
        form.setFieldsValue({
            ...room,
            bedding: room.bedding === 'Input No of Bed Manually' ? 'Input No of Bed Manually' : room.bedding
        });
        setImageFiles(room.imageurls.map((url, index) =>
            ({ uid: index, name: `Image ${index + 1}`, status: 'done', url })));
        if (room.bedding === 'Input No of Bed Manually') {
            setManualBeds(room.manualBeds);
        }
    };

    const handleDelete = async (roomId) => {
        try {
            await axios.delete(`/api/rooms/delete/${roomId}`);
            message.success('Room deleted successfully');
            fetchRooms();
        } catch (error) {
            message.error('Failed to delete room');
        }
    };

    const handleModalOk = async () => {
        try {
            const values = await form.validateFields();
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });

            // Add image files to form data
            imageFiles.forEach((file) => {
                if (file.originFileObj) {
                    formData.append('images', file.originFileObj); // Only append new files
                }
            });

            // Append manual bed count if "Input No of Bed Manually" is selected
            if (values.bedding === 'Input No of Bed Manually') {
                formData.append('manualBeds', manualBeds);
            }

            if (editingRoom) {
                await axios.put(`/api/rooms/updateroom/${editingRoom._id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                message.success('Room updated successfully');
            }
            setIsModalVisible(false);
            form.resetFields();
            fetchRooms();
        } catch (error) {
            message.error('Failed to save room');
        }
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleImageChange = ({ fileList }) => {
        const newFiles = fileList.filter(file => !file.url);  // Filter out new files (without url)
        const existingFiles = imageFiles.filter(file => file.url);  // Keep existing files

        // Combine new files with existing ones and set the updated list
        setImageFiles([...existingFiles, ...newFiles]);
    };

    const handleImageRemove = async (file) => {
        if (file.url) {
            try {
                // Remove the image URL from the backend
                await axios.post(`/api/rooms/remove-image/${editingRoom._id}`, { imageUrl: file.url });
                message.success('Image removed successfully');

                // Update the imageFiles state immediately to remove the image from the list
                setImageFiles(prevFiles => prevFiles.filter(img => img.url !== file.url));
            } catch (error) {
                message.error('Failed to remove image');
            }
        } else {
            // Remove the image from the list (for new files not yet uploaded to the backend)
            setImageFiles(prevFiles => prevFiles.filter(img => img.uid !== file.uid));
        }
    };


    const uploadButton = (
        <div>
            <UploadOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    return (
        <div className='container'>
            <h1>Room Management</h1>
            <Button className='mb-3' icon={<PlusOutlined />} type="primary" onClick={() => navigate('/admin/addroom')}>
                Add New Room
            </Button>
            <div className='row'>
                {rooms.map(room => (
                    <div className='col-md-4 mb-4' key={room._id}>
                        <Card
                            hoverable
                            cover={
                                <img
                                    alt="Room"
                                    src={room.imageurls[0] || 'https://via.placeholder.com/150'}
                                    style={{ height: '200px', objectFit: 'cover' }}
                                />
                            }
                            actions={[
                                <Button type="primary" onClick={() => handleEdit(room)}>Edit</Button>,
                                <Button type="link" danger onClick={() => handleDelete(room._id)}>Delete</Button>,
                            ]}
                        >
                            <h1 style={{ color: '#443d80' }}><strong>{room.name}</strong></h1>
                            <p><strong>Room Type:</strong> {room.type}</p>
                            <p><strong>Room View:</strong> {room.roomview}</p>
                            <p><strong>Room Size:</strong> {room.roomsize} sq meters</p>
                            <p><strong>Bed Type:</strong> {room.bedding}</p>
                            <p><strong>Max Adults:</strong> {room.maxadult}</p>
                            <p><strong>Max Children:</strong> {room.maxchild}</p>
                            <p><strong>Extra Bed:</strong> {room.extrabed ? 'Yes' : 'No'}</p>
                            <p><strong>Smoking Policy:</strong> {room.smokingpolicy ? 'Allowed' : 'No Smoking'}</p>
                        </Card>
                    </div>
                ))}
            </div>

            {/* Modal for Editing Room */}
            <Modal
                title="Edit Room"
                visible={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okText="Save"
                cancelText="Cancel"
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="name" label="Room Name" rules={[{ required: true, message: 'Please enter room name' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="description" label="Room Description" rules={[{ required: true, message: 'Please enter your room description' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="maxcount" label="Number of Rooms" rules={[{ required: true, message: 'Please enter the number of rooms' }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="rentperday" label="Rent Per Day" rules={[{ required: true, message: 'Please enter the rent per day' }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="type" label="Room Type" rules={[{ required: true, message: 'Please select room type' }]}>
                        <Select placeholder="Select Room Type">
                            {roomTypeOptions.map(option => (
                                <Option key={option} value={option}>{option}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="roomview" label="Room View" rules={[{ required: true, message: 'Please select room view' }]}>
                        <Select placeholder="Select Room View">
                            {roomViewOptions.map(option => (
                                <Option key={option} value={option}>{option}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item name="roomsize" label="Room Size (sq meters)" rules={[{ required: true, message: 'Please enter room size' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="maxadult" label="Max Adults" rules={[{ required: true, message: 'Please enter max adults' }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="maxchild" label="Max Children" rules={[{ required: true, message: 'Please enter max children' }]}>
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item name="smokingpolicy" label="Smoking Policy" rules={[{ required: true }]}>
                        <Select placeholder="Select Smoking Policy">
                            <Option value={true}>Allowed</Option>
                            <Option value={false}>No Smoking</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="extrabed"
                        label="Extra Bed"
                        valuePropName="checked" // This ensures that the Form controls the 'checked' state of the Switch
                    >
                        <Switch onChange={(checked) => form.setFieldsValue({ extrabed: checked })} />
                    </Form.Item>


                    <Form.Item name="bedding" label="Bedding" rules={[{ required: true, message: 'Please select bed type' }]}>
                        <Select placeholder="Select Bed Type" onChange={(value) => form.setFieldsValue({ bedding: value })}>
                            {beddingOptions.map(option => (
                                <Option key={option} value={option}>{option}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {form.getFieldValue('bedding') === 'Input No of Bed Manually' && (
                        <Form.Item name="manualBeds" label="Number of Beds" rules={[{ required: true, message: 'Please enter number of beds' }]}>
                            <Input type="number" value={manualBeds} onChange={(e) => setManualBeds(e.target.value)} />
                        </Form.Item>
                    )}

                    <Form.Item name="facilities" label="Facilities">
                        <Select mode="multiple" allowClear placeholder="Select Facilities" value={form.getFieldValue('facilities')}>
                            {facilityOptions.map(option => (
                                <Option key={option} value={option}>{option}</Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item name="images" label="Upload Images (up to 5)">
                        <Upload
                            listType="picture-card"
                            multiple
                            accept="image/*"
                            beforeUpload={() => false}
                            onChange={handleImageChange}
                            onRemove={handleImageRemove} // Handle image removal
                            fileList={imageFiles}
                        >
                            {imageFiles.length >= 5 ? null : uploadButton}
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default RoomingListScreen;
