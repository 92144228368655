import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Rate, Modal, Select } from 'antd';
import { PhoneOutlined, MailOutlined, HomeOutlined, CompassOutlined } from '@ant-design/icons';
import './Room.css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CurrencyContext } from './CurrencyContext';

const { Option } = Select;

function Navbar() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const [showModal, setShowModal] = useState(false);
    const [showMapModal, setShowMapModal] = useState(false); // For Google Maps modal
    const [branding, setBranding] = useState({ navbarColorColor: '#fff' });
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const [hotelInfo, setHotelInfo] = useState({
        hotelName: '',
        starRating: 1,
        email: '',
        phoneNumber: '',
        address: '',
        mapLocation: '',
        description: '',
        officialWebsite: '',
        currency: 'USD',
        preferredLanguage: 'English',
        factsheet: '',
        featureImage: '',
        logo: ''
    });

    const { currency, setCurrency } = useContext(CurrencyContext);

    useEffect(() => {
        const fetchHotelInfo = async () => {
            try {
                const response = await axios.get('/api/hotelInfo/getallinfo');
                setHotelInfo(response.data);
            } catch (error) {
                console.error('Error fetching hotel info:', error);
            }
        };
        fetchHotelInfo();
    }, []);

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const response = await axios.get('/api/branding/getallbranding');
                setBranding(response.data || { roomNameColor: '#443d80' });
            } catch (error) {
                console.error("Error fetching branding settings:", error);
            }
        };

        fetchBranding();
    }, []);

    const logout = () => {
        localStorage.removeItem('currentUser');
        window.location.href = '/login';
    };

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const toggleMapModal = () => {
        setShowMapModal(!showMapModal);
    };

    const handleCurrencyChange = (value) => {
        setCurrency(value); // Update currency in CurrencyContext
    };

    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng); // Update state to reflect the selected language
    };

    return (
        <div>
            {/* Sub-navbar for Hotel Information (hidden on mobile) */}
            <div className="sub-navbar d-none d-lg-flex justify-content-end " style={{ fontSize: '0.8rem', backgroundColor: '#f5f5f5' }}>
                <div className="d-flex pt-2 text-muted align-items-center mb-2">
                    <span className="me-3">
                        <PhoneOutlined /> {hotelInfo?.phoneNumber || 'N/A'}
                    </span>
                    <span className="me-3">
                        <MailOutlined /> {hotelInfo?.email || 'N/A'}
                    </span>
                    <span className="me-2">
                        <HomeOutlined /> {hotelInfo?.address || 'N/A'}
                    </span>
                    <span className="align-items-center">
                        <Select
                            value={language}
                            onChange={handleLanguageChange}
                            bordered={false}
                            style={{ width: 110, fontSize: '0.8rem' }}
                        >
                            <Option value="en">🇺🇸 {t('English')}</Option>
                            <Option value="vi">🇻🇳 {t('Vietnamese')}</Option>
                            <Option value="ko">🇰🇷 {t('Korean')}</Option>
                            <Option value="zh">🇨🇳 {t('Chinese')}</Option>
                            <Option value="ja">🇯🇵 {t('Japanese')}</Option>
                        </Select>
                    </span>
                    <span className="align-items-center d-flex">
                        <Select
                            defaultValue={currency}
                            style={{ width: 100, border: 'none', backgroundColor: 'transparent', fontSize: '0.7rem' }} // Fine-tune width, font size, and padding
                            bordered={false}
                            onChange={handleCurrencyChange}
                            dropdownStyle={{ fontSize: '0.7rem' }} // Adjust dropdown font size
                        >
                            <Option value="USD">$ USD</Option>
                            <Option value="VND">₫ VND</Option>
                            <Option value="EUR">€ EUR</Option>
                            <Option value="JPY">¥ JPY</Option>  {/* Japanese Yen */}
                            <Option value="CNY">¥ CNY</Option>  {/* Chinese Yuan */}
                            <Option value="KRW">₩ KRW</Option>  {/* Korean Won */}
                            <Option value="THB">฿ THB</Option>  {/* Thai Baht */}
                        </Select>
                    </span>
                </div>
            </div>

            {/* Main Navbar */}
            <nav className="navbar navbar-expand-lg" style={{ backgroundColor: branding.navbarColor }}>
                <a href="/home" className="d-flex align-items-center text-gray" style={{ textDecoration: 'none' }}>
                    <img
                        className='ms-4'
                        alt="hotel logo"
                        src={hotelInfo?.logo || '/defaultLogo.png'} // Fallback to default if no logo is available
                        style={{ maxHeight: '50px' }} // Adjust size as needed
                    />
                    <div className="ms-3 d-none d-lg-block">
                        <span className="h5 font-weight-bold" style={{ color: '#6c757d' }}>{hotelInfo?.hotelName}  </span>
                        <Rate disabled allowHalf value={hotelInfo?.starRating} style={{ color: branding.starRatingColor, fontSize: '14px' }} />
                    </div>
                </a>
                <button
                    className="navbar-toggler btn-light"
                    type="button"
                    onClick={toggleModal}
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse d-none d-lg-block me-3" id="navbarNav">
                    <ul className="navbar-nav ms-auto" >
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                href={hotelInfo?.socialMediaLinks?.officialWebsite || '#'}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: branding.navbarMenuColor }}
                                onMouseEnter={(e) => (e.target.style.color = 'black')}
                                onMouseLeave={(e) => (e.target.style.color = branding.navbarMenuColor)}
                            >
                                <strong>{t('navbar.homepage')}</strong>
                            </a>
                        </li>
                        <li className="nav-item">
                            <Link
                                to="/mybooking"
                                className="nav-link"
                                style={{ color: branding.navbarMenuColor }}
                                onMouseEnter={(e) => (e.target.style.color = 'black')}
                                onMouseLeave={(e) => (e.target.style.color = branding.navbarMenuColor)}
                            >
                                <strong>{t('navbar.myBooking')}</strong>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <button
                                className="nav-link"
                                onClick={toggleMapModal}
                                aria-label="Open Google Maps Directions"
                                style={{ color: branding.navbarMenuColor }}
                                onMouseEnter={(e) => (e.target.style.color = 'black')}
                                onMouseLeave={(e) => (e.target.style.color = branding.navbarMenuColor)}

                            >
                                <CompassOutlined /> <strong>{t('navbar.direction')}</strong>
                            </button>
                        </li>
                        {user ? (
                            <li className="nav-item dropdown">
                                <button
                                    className="btn btn-secondary dropdown-toggle apply-button"
                                    type="button"
                                    style={{
                                        backgroundColor: branding.buttonColor, // Apply buttonColor from branding
                                        borderColor: branding.buttonColor,     // Match border color for consistency
                                    }}
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <i className="fa-solid fa-user"></i> {user.name}
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="/profile">{t('navbar.profile')}</a></li>
                                    {user.isAdmin && <li><a className="dropdown-item" href="/admin/dashboard">{t('navbar.admin_panel')}</a></li>}
                                    <li><a className="dropdown-item" href="/login" onClick={logout}>{t('navbar.logout')}</a></li>
                                </ul>
                            </li>
                        ) : (
                            <>
                                <li className="nav-item">
                                    <a className="nav-link" href="/register"
                                        style={{ color: branding.navbarMenuColor }}
                                        onMouseEnter={(e) => (e.target.style.color = 'black')}
                                        onMouseLeave={(e) => (e.target.style.color = branding.navbarMenuColor)}
                                    ><strong>{t('navbar.register')}</strong></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/login" style={{ color: branding.navbarMenuColor }}
                                        onMouseEnter={(e) => (e.target.style.color = 'black')}
                                        onMouseLeave={(e) => (e.target.style.color = branding.navbarMenuColor)}
                                    ><strong>{t('navbar.login')}</strong></a>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </nav>

            {/* Modal Popup for Mobile View */}
            {showModal && (
                <div className="modal fade show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                        <div className="modal-content" style={{ backgroundColor: branding.footerColor }}>
                            <div className="modal-header">
                                <button type="button" className="btn-close btn-close-white" onClick={toggleModal}></button>
                            </div>
                            <div className="modal-body text-center text-white">
                                <div className="mb-4">
                                    <span className="h5 font-weight-bold">{hotelInfo?.hotelName}</span>
                                    <Rate disabled allowHalf value={hotelInfo?.starRating} style={{ fontSize: '18px', color: '#f8c94a' }} />
                                </div>
                                <div className="text-white">
                                    <p style={{ fontSize: '0.8rem' }}><PhoneOutlined /> {hotelInfo?.phoneNumber}</p>
                                    <p style={{ fontSize: '0.8rem' }}><MailOutlined /> {hotelInfo?.email}</p>
                                    <p style={{ fontSize: '0.8rem' }}> {hotelInfo?.address}</p>
                                    <p>{' '}
                                        <Select
                                            value={language}
                                            onChange={handleLanguageChange}
                                            bordered={false}
                                            style={{
                                                width: 130,
                                                color: 'white',
                                                backgroundColor: 'transparent',
                                                fontSize: '0.8rem',
                                                zIndex: 1200,
                                            }}
                                            dropdownStyle={{ fontSize: '0.7rem', zIndex: 1100 }}
                                            getPopupContainer={(trigger) => trigger.parentNode}
                                        >
                                            <Option value="en">🇺🇸 English</Option>
                                            <Option value="vi">🇻🇳 Vietnamese</Option>
                                            <Option value="ko">🇰🇷 Korean</Option>
                                            <Option value="zh">🇨🇳 Chinese</Option>
                                            <Option value="ja">🇯🇵 Japanese</Option>
                                        </Select>
                                    </p>

                                    <p className="d-flex align-items-center justify-content-center" style={{ fontSize: '0.8rem' }}>
                                        <Select
                                            defaultValue={currency}
                                            style={{
                                                width: 100,
                                                border: 'none',
                                                backgroundColor: 'transparent',
                                                fontSize: '0.7rem',
                                                color: 'white',
                                                zIndex: 1100, // Ensure it's above other elements
                                            }}
                                            dropdownStyle={{ fontSize: '0.7rem', zIndex: 1100 }} // Ensure dropdown menu is on top
                                            bordered={false}
                                            onChange={handleCurrencyChange}
                                            getPopupContainer={(trigger) => trigger.parentNode} // Ensures dropdown renders within the modal
                                        >
                                            <Option value="USD">$ USD</Option>
                                            <Option value="VND">₫ VND</Option>
                                            <Option value="EUR">€ EUR</Option>
                                            <Option value="JPY">¥ JPY</Option>  {/* Japanese Yen */}
                                            <Option value="CNY">¥ CNY</Option>  {/* Chinese Yuan */}
                                            <Option value="KRW">₩ KRW</Option>  {/* Korean Won */}
                                            <Option value="THB">฿ THB</Option>  {/* Thai Baht */}
                                        </Select>
                                    </p>
                                </div>
                                <hr />
                                <ul className="list-group border-0 mt-4">
                                    {user ? (
                                        <>
                                            <li className="list-group-item border-0 bg-transparent text-white">
                                                <a href="/profile" style={{ color: 'white', textDecoration: 'none' }}>Profile</a>
                                            </li>
                                            {user.isAdmin && (
                                                <li className="list-group-item border-0 bg-transparent text-white">
                                                    <a href="/admin" style={{ color: 'white', textDecoration: 'none' }}>Admin Panel</a>
                                                </li>
                                            )}
                                            <li className="list-group-item border-0 bg-transparent text-white">
                                                <a href="/login" onClick={logout} style={{ color: 'white', textDecoration: 'none' }}>Logout</a>
                                            </li>
                                        </>
                                    ) : (
                                        <>
                                            <li className="list-group-item border-0 bg-transparent text-white">
                                                <a href="/register" style={{ color: 'white', textDecoration: 'none' }}>Register</a>
                                            </li>
                                            <li className="list-group-item border-0 bg-transparent text-white">
                                                <a href="/login" style={{ color: 'white', textDecoration: 'none' }}>Login</a>
                                            </li>
                                        </>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Google Maps Modal for Directions */}
            <Modal
                visible={showMapModal}
                footer={null}
                onCancel={toggleMapModal}
                title="Find Us On Maps"
            >
                <iframe
                    title="Maps Navigation"
                    src={`https://www.google.com/maps?q=${encodeURIComponent(hotelInfo?.address)}&output=embed`}
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </Modal>
        </div>
    );
}

export default Navbar;
