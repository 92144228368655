
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { QRCodeCanvas } from 'qrcode.react'; // Correct import
import { Modal, Button } from 'antd';

const ConfirmationScreen = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [booking, setBooking] = useState(null);
    const [hotelInfo, setHotelInfo] = useState(null);
    const [branding, setBranding] = useState(null);
    const [extraBedPrice, setExtraBedPrice] = useState(null);
    const [hotelRules, setHotelRules] = useState(null);
    const [isRulesModalVisible, setIsRulesModalVisible] = useState(false); // Modal visibility state


    const confirmationRef = useRef(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const orderId = params.get('orderId');

        if (!location.state || !location.state.booking || !location.state.hotelInfo) {
            const fetchBookingDetails = async () => {
                try {
                    // Fetch booking details
                    const bookingResponse = await fetch(`/api/bookings/getBookingById?orderId=${orderId}`);
                    const bookingData = await bookingResponse.json();
                    setBooking(bookingData.booking);

                    // Fetch hotel info
                    const hotelInfoResponse = await fetch('/api/hotelInfo/getallinfo');
                    const hotelInfoData = await hotelInfoResponse.json();
                    setHotelInfo(hotelInfoData);
                } catch (error) {
                    console.error('Error fetching booking details:', error);
                    navigate('/'); // Redirect if there's an error
                }
            };

            if (orderId) fetchBookingDetails();
            else navigate('/'); // Redirect if orderId is missing
        } else {
            // Set state from location
            setBooking(location.state.booking);
            setHotelInfo(location.state.hotelInfo);
        }
    }, [location, navigate]);

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const brandingResponse = await fetch('/api/branding/getallbranding');
                const brandingData = await brandingResponse.json();
                setBranding(brandingData);
            } catch (error) {
                console.error('Error fetching branding info:', error);
            }
        };

        if (!location.state?.branding) {
            fetchBranding();
        } else {
            setBranding(location.state.branding);
        }
    }, [location.state?.branding]);

    // Fetch extra bed price
    useEffect(() => {
        const fetchExtraBedPrice = async () => {
            try {
                const response = await fetch('/api/extrabedprice/get');
                const data = await response.json();
                setExtraBedPrice(data.price);
            } catch (error) {
                console.error('Error fetching extra bed price:', error);
            }
        };

        fetchExtraBedPrice();
    }, []);

    useEffect(() => {
        const fetchHotelRules = async () => {
            try {
                const response = await fetch('/api/hotelrules/get');
                const rulesData = await response.json();
                setHotelRules(rulesData);
            } catch (error) {
                console.error('Error fetching hotel rules:', error);
            }
        };

        fetchHotelRules();
    }, []);



    const handleAddToCalendar = () => {
        if (!booking) return;

        const startDate = moment(booking.fromdate, 'DD-MM-YYYY').format('YYYYMMDDTHHmmss');
        const endDate = moment(booking.todate, 'DD-MM-YYYY').format('YYYYMMDDTHHmmss');

        const icsContent = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:Booking Confirmation - ${hotelInfo?.hotelName}
DESCRIPTION:Thank you ${booking.guestName} for booking at ${hotelInfo?.hotelName}.
DTSTART:${startDate}
DTEND:${endDate}
LOCATION:${hotelInfo?.address}
END:VEVENT
END:VCALENDAR`;

        const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
        saveAs(blob, `Booking_${booking.bookingId}.ics`);
    };

    const calculateTotalDays = () => {
        if (booking) {
            const fromdate = moment(booking.fromdate, 'DD-MM-YYYY');
            const todate = moment(booking.todate, 'DD-MM-YYYY');
            return todate.diff(fromdate, 'days');
        }
        return 0;
    };


    const handleDownloadPDF = async () => {
        if (!confirmationRef.current) return;

        const element = confirmationRef.current;

        // Render the element into a high-resolution canvas
        const canvas = await html2canvas(element, {
            scale: 2, // Higher resolution for clear rendering
            useCORS: true, // Ensure external images (e.g., logo) load
            logging: true, // Enable logging for debugging if needed
        });

        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4'); // Portrait mode PDF, A4 size

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = pdfWidth; // Fit to PDF width
        const imgHeight = (canvas.height * pdfWidth) / canvas.width; // Maintain aspect ratio

        let position = 0;

        // Add the first portion of content
        while (position < imgHeight) {
            pdf.addImage(imgData, 'PNG', 0, position * -1, imgWidth, imgHeight);
            position += pdfHeight; // Move to the next page
            if (position < imgHeight) pdf.addPage();
        }

        pdf.save(`Booking_${booking.bookingId}.pdf`);
    };


    const showHotelRulesModal = () => {
        setIsRulesModalVisible(true);
    };

    const hideHotelRulesModal = () => {
        setIsRulesModalVisible(false);
    };

    if (!booking || !hotelInfo || !branding || extraBedPrice === null) {
        return <p>Loading booking details... Please wait.</p>;
    }

    const addonServiceDetailsTable =
        booking.addonServices.length > 0 ? (
            <div
                style={{
                    marginTop: '20px',
                    border: '1px solid #ddd',
                    borderRadius: '8px',
                    overflow: 'hidden',
                    backgroundColor: '#f8f8f8',
                }}
            >
                <table
                    style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        textAlign: 'left',
                    }}
                >
                    <thead>
                        <tr style={{ backgroundColor: branding.headingColor, color: '#fff' }}>
                            <th style={{ padding: '10px' }}>Service Name</th>
                            <th style={{ padding: '10px' }}>Description</th>
                            <th style={{ padding: '10px' }}>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {booking.addonServices.map((service, index) => (
                            <tr key={index}>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{service.serviceName}</td>
                                <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                    {service.serviceDescription || 'N/A'}
                                </td>
                                <td
                                    style={{
                                        padding: '10px',
                                        borderBottom: '1px solid #ddd',
                                        color: branding.priceTagColor,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {service.servicePrice.toLocaleString()} VND
                                </td>

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        ) : (
            <p style={{ marginTop: '20px' }}>No add-on services selected.</p>
        );

    return (
        <div
            ref={confirmationRef}
            style={{
                fontFamily: 'Arial, sans-serif',
                padding: '10px',
                backgroundColor: branding.pageBackgroundImage ? `url(${branding.pageBackgroundImage})` : '#f8f4f4',
                backgroundSize: 'cover',
                minHeight: '100vh',
            }}
        >
            <div style={{
                maxWidth: '95%',
                margin: 'auto',
                backgroundColor: branding.navbarColor,
                borderRadius: '10px',
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}>
                <div style={{
                    backgroundColor: branding.headingColor,
                    color: '#fff',
                }}>
                    <h1 style={{
                        marginLeft: '10px'
                    }}
                    >Booking Confirmation</h1>
                </div>
                <div style={{ padding: '20px' }}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: '20px',
                            marginBottom: '20px',
                        }}
                    >
                        <div>
                            <img
                                src={hotelInfo.logo}
                                alt="Hotel Logo"
                                style={{
                                    maxWidth: '100px',
                                    borderRadius: '10px',
                                }}
                            />
                        </div>
                        <div style={{ textAlign: 'right', lineHeight: '1' }}>
                            <h1 style={{ fontSize: '50px', fontWeight: 'bold', color: branding.roomNameColor }}>{hotelInfo.hotelName}</h1>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    gap: '5px',
                                }}
                            >
                                {/* Address and Phone */}
                                <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
                                    <p style={{ margin: 0 }}>Address: {hotelInfo.address},</p>
                                    <p style={{ margin: 0 }}>Phone: {hotelInfo.phoneNumber}</p>
                                </div>

                                {/* Website, Email, View Map inline */}
                                <div style={{ display: 'flex', gap: '15px', marginTop: '5px', flexWrap: 'wrap' }}>
                                    <p style={{ margin: 0 }}>
                                        Website:{" "}
                                        <a
                                            href={hotelInfo.socialMediaLinks?.officialWebsite}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ color: '#007bff', textDecoration: 'underline' }}
                                        >
                                            {hotelInfo.socialMediaLinks?.officialWebsite || 'N/A'}
                                        </a>
                                    </p>
                                    <p style={{ margin: 0 }}>Email: {hotelInfo.email}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table
                        style={{
                            width: '100%',
                            marginTop: '20px',
                            textAlign: 'center',
                            backgroundColor: '#f8f4f4',
                            borderRadius: '8px',
                            lineHeight: '1',
                        }}
                    >
                        <tbody>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '10px' }}>Booking ID</th>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        padding: '10px',
                                        color: branding.paragraphColor,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {booking.bookingId}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '10px' }}>Transaction ID</th>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        padding: '10px',
                                        color: branding.paragraphColor,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {booking.transactionId || 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '10px' }}>Payment Method</th>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        padding: '10px',
                                        color: branding.paragraphColor,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {booking.paymentMethod || 'N/A'}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '10px' }}>Check-In Date</th>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        padding: '10px',
                                        color: branding.paragraphColor,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {moment(booking.fromdate, 'DD-MM-YYYY').format('DD-MMM-YYYY')}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '10px' }}>Check-Out Date</th>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        padding: '10px',
                                        color: branding.paragraphColor,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {moment(booking.todate, 'DD-MM-YYYY').format('DD-MMM-YYYY')}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ textAlign: 'left', padding: '10px' }}>Total Night</th>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        padding: '10px',
                                        color: branding.paragraphColor,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {booking.totaldays}
                                </td>
                            </tr>
                            <tr
                                style={{
                                    backgroundColor: branding.headingColor,
                                }}
                            >
                                <th
                                    style={{
                                        textAlign: 'left',
                                        padding: '10px',
                                        color: 'white',
                                    }}
                                >
                                    Grand Total
                                </th>
                                <td
                                    style={{
                                        textAlign: 'right',
                                        padding: '10px',
                                        fontWeight: 'bold',
                                        fontSize: '20px',
                                        color: '#fff',
                                    }}
                                >
                                    {booking.totalamount.toLocaleString()} VND
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colSpan="2"
                                    style={{
                                        textAlign: 'center',
                                        padding: '10px',
                                        fontSize: '14px',
                                        color: branding.paragraphColor,
                                    }}
                                >
                                    <strong>All prices are quoted in VND, inclusive of 5% service charge and 10% Tax (VAT).</strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3 className="mt-3">Guest Details</h3>
                    <div
                        style={{
                            marginTop: '20px',
                            border: '1px solid #ddd',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            backgroundColor: '#f8f8f8',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: branding.headingColor,
                                color: '#fff',
                                padding: '10px',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            Guest Details
                        </div>
                        <table
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                textAlign: 'left',
                            }}
                        >
                            <tbody>
                                <tr>
                                    <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Guest Name</th>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd', fontWeight: 'bold' }}>{booking.guestName}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Email</th>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{booking.guestEmail}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Phone Number</th>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{booking.guestPhoneNumber}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Nationality</th>
                                    <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>{booking.guestNationality}</td>
                                </tr>
                                <tr>
                                    <th style={{ padding: '10px' }}>Special Request</th>
                                    <td style={{ padding: '10px' }}>{booking.specialRequest || 'None'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <h3 className="mt-3">Room Details</h3>
                    {booking.rooms && booking.rooms.length > 0 ? (
                        booking.rooms.map((room, index) => (
                            <div
                                key={index}
                                style={{
                                    marginTop: '20px',
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    backgroundColor: '#f8f8f8',
                                }}
                            >
                                {/* Room Header */}
                                <div
                                    style={{
                                        backgroundColor: branding.headingColor,
                                        color: '#fff',
                                        padding: '10px',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Room {index + 1}: {room.room || "N/A"}
                                </div>

                                {/* Room Table */}
                                <table
                                    style={{
                                        width: '100%',
                                        borderCollapse: 'collapse',
                                        textAlign: 'left',
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Number of Rooms</th>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                                {room.numberOfRooms || "N/A"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Rate Plan</th>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                                {room.ratePlanName || "N/A"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Rate</th>
                                            <td
                                                style={{
                                                    padding: '10px',
                                                    borderBottom: '1px solid #ddd',
                                                    color: branding.priceTagColor,
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {room.ratePlanRate
                                                    ? room.ratePlanRate.toLocaleString() + " VND"
                                                    : "0 VND"}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Adults</th>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                                {room.adults || 0}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Children</th>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                                {room.children || 0}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>Extra Bed</th>
                                            <td style={{ padding: '10px', borderBottom: '1px solid #ddd' }}>
                                                {room.extraBed && Object.values(room.extraBed).some(value => value === true)
                                                    ? `YES (${extraBedPrice?.toLocaleString()} VND per night)`
                                                    : "NO"}
                                                {room.extraBed && Object.values(room.extraBed).some(value => value) && (
                                                    <p><strong>Total Extra Bed Price:</strong> {(extraBedPrice * calculateTotalDays()).toLocaleString()} VND</p>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th style={{ padding: '10px' }}>Cancellation Policy</th>
                                            <td style={{ padding: '10px' }}>
                                                {room.cancellationPolicy || "Flexible"}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                {/* Rate Breakdown Table */}
                                {Array.isArray(room.rateBreakdown) && room.rateBreakdown.length > 0 ? (
                                    <div style={{ marginTop: '20px' }}>
                                        <h4 style={{ marginBottom: '10px', fontWeight: 'bold' }}>Rate Breakdown</h4>
                                        <table
                                            style={{
                                                width: '100%',
                                                borderCollapse: 'collapse',
                                                textAlign: 'center',
                                                border: '1px solid #ddd',
                                            }}
                                        >
                                            <thead>
                                                <tr style={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                                                    <th style={{ padding: '10px', border: '1px solid #ddd' }}>Date</th>
                                                    <th style={{ padding: '10px', border: '1px solid #ddd' }}>Original Rate (VND)</th>
                                                    <th style={{ padding: '10px', border: '1px solid #ddd' }}>Promotion</th>
                                                    <th style={{ padding: '10px', border: '1px solid #ddd' }}>Promotion Discount (%)</th>
                                                    <th style={{ padding: '10px', border: '1px solid #ddd' }}>Promo Code Discount</th>
                                                    <th style={{ padding: '10px', border: '1px solid #ddd' }}>Final Rate (VND)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {room.rateBreakdown.map((rate, i) => (
                                                    <tr key={i}>
                                                        <td style={{ padding: '10px', border: '1px solid #ddd' }}>{rate.date || 'N/A'}</td>
                                                        <td
                                                            style={{
                                                                padding: '10px',
                                                                border: '1px solid #ddd',
                                                                color: '#000',
                                                            }}
                                                        >
                                                            {rate.originalRate ? `${rate.originalRate.toLocaleString()} VND` : '0 VND'}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: '10px',
                                                                border: '1px solid #ddd',
                                                                color: '#666',
                                                                fontStyle: 'italic',
                                                            }}
                                                        >
                                                            {rate.promotion || 'No Promotion'}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: '10px',
                                                                border: '1px solid #ddd',
                                                                color: '#ff4d4f',
                                                            }}
                                                        >
                                                            {rate.promotionDiscount ? `-${rate.promotionDiscount}%` : '0%'}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: '10px',
                                                                border: '1px solid #ddd',
                                                                color: '#ff7f50',
                                                            }}
                                                        >
                                                            {rate.promoCodeName}
                                                        </td>
                                                        <td
                                                            style={{
                                                                padding: '10px',
                                                                border: '1px solid #ddd',
                                                                color: branding.priceTagColor,
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {rate.finalRate
                                                                ? `${rate.finalRate.toLocaleString()} VND`
                                                                : `${rate.originalRate.toLocaleString()} VND`}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <p style={{ marginTop: '10px', fontStyle: 'italic', color: '#888' }}>
                                        No rate breakdown available.
                                    </p>
                                )}


                            </div>
                        ))
                    ) : (
                        <p style={{ marginTop: '20px', fontWeight: 'bold' }}>No room details available.</p>
                    )}

                    <h3 className='mt-3'>Add-On Services</h3>
                    <div>
                        {addonServiceDetailsTable}
                    </div>


                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {/* QR Code Column */}
                            <div style={{ flex: '1', textAlign: 'center', marginRight: '10px' }}>
                                <QRCodeCanvas
                                    value={`${window.location.origin}/mybooking`}
                                    size={120}
                                    style={{ marginBottom: '10px' }}
                                />
                                <p style={{ textAlign: 'center' }}>Scan to manage booking</p>
                            </div>

                            {/* Hotel Rules and View on Map Column */}
                            <div style={{ flex: '1', textAlign: 'center', margin: '0 10px' }}>
                                {/* Read Our Hotel Rule */}
                                <button
                                    onClick={showHotelRulesModal}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        padding: 0,
                                        color: branding.roomNameColor,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        display: 'block',
                                        margin: '10px 0',
                                    }}
                                >
                                    Read Our Hotel Rules
                                </button>
                                {/* View on Map */}
                                <button
                                    onClick={() => window.open(hotelInfo.mapLocation, '_blank', 'noopener,noreferrer')}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        padding: 0,
                                        color: '#007bff',
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        display: 'block',
                                        margin: '10px 0',
                                    }}
                                >
                                    View on Map
                                </button>
                            </div>

                            {/* Download Booking and Add to Calendar Column */}
                            <div style={{ flex: '1', textAlign: 'center', marginLeft: '10px' }}>
                                {/* Download Booking */}
                                <button
                                    onClick={handleDownloadPDF}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        padding: 0,
                                        color: branding.roomNameColor,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        display: 'block',
                                        margin: '10px 0',
                                    }}
                                >
                                    Download Booking
                                </button>
                                {/* Add to Calendar */}
                                <button
                                    onClick={handleAddToCalendar}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        padding: 0,
                                        color: branding.roomNameColor,
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        display: 'block',
                                        margin: '10px 0',
                                    }}
                                >
                                    Add to Calendar
                                </button>
                            </div>
                        </div>

                        {/* Hotel Rules Modal */}
                        <Modal
                            title="Hotel Rules"
                            visible={isRulesModalVisible}
                            onCancel={hideHotelRulesModal}
                            footer={
                                <Button type="primary" onClick={hideHotelRulesModal}>
                                    Close
                                </Button>
                            }
                        >
                            {hotelRules ? (
                                <div style={{ lineHeight: '1.8' }}>
                                    <p><strong>Check-In Time:</strong> {hotelRules.checkInTimeFrom}</p>
                                    <p><strong>Check-Out Time:</strong> {hotelRules.checkOutTimeBefore}</p>
                                    <p><strong>Early Check-In Charge:</strong> {hotelRules.earlyCheckInCharge} VND</p>
                                    <p><strong>Late Check-Out Charge:</strong> {hotelRules.lateCheckOutCharge} VND</p>
                                    <p><strong>Smoking Rule:</strong> {hotelRules.smokingRule || 'Not specified'}</p>
                                    <p><strong>Pet Allowed:</strong> {hotelRules.petAllow ? 'Yes' : 'No'}</p>
                                    <p><strong>Damage Compensation:</strong> {hotelRules.damageCompensation}</p>
                                    <p><strong>Housekeeping Policy:</strong> {hotelRules.houseKeepingPolicy}</p>
                                    <p><strong>No Show Charge:</strong> {hotelRules.noShowCharge}%</p>
                                    <p><strong>Lost and Found Policy:</strong> {hotelRules.lostAndFoundPolicy}</p>
                                    <p><strong>Refund Policy:</strong> {hotelRules.refundPolicy}</p>
                                    <p><strong>Force Majeure Event:</strong> {hotelRules.forceMajeureEvent}</p>
                                    <p><strong>Hotel Rule:</strong> {hotelRules.hotelRule}</p>
                                </div>
                            ) : (
                                <p>Loading hotel rules...</p>
                            )}
                        </Modal>
                    </div>

                    <p
                        style={{
                            textAlign: 'center',
                            marginTop: '20px',
                            fontSize: '18px',
                            fontWeight: 'bold',
                            color: branding.roomNameColor,
                        }}
                    >
                        Thank you for booking with us!
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationScreen;
