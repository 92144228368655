import React, { useState, useEffect } from 'react';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AnalyticsScreen = () => {
    const [dateRange, setDateRange] = useState([null, null]);
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchBookings = async () => {
            if (!dateRange[0] || !dateRange[1]) return;
            setLoading(true);
            try {
                const response = await axios.get('/api/bookings/reportbookings', {
                    params: {
                        startDate: dateRange[0].toISOString(),
                        endDate: dateRange[1].toISOString(),
                    },
                });
                setBookings(response.data);
            } catch (error) {
                console.error('Error fetching bookings:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchBookings();
    }, [dateRange]);

    const sortDates = (dateStrings) => {
        return dateStrings.sort((a, b) => new Date(a) - new Date(b));
    };

    const formatBookingsChartData = () => {
        const bookingsByDate = bookings.reduce((acc, booking) => {
            const date = new Date(booking.createdAt).toLocaleDateString();
            acc[date] = (acc[date] || 0) + 1;
            return acc;
        }, {});

        const labels = sortDates(Object.keys(bookingsByDate));
        const data = labels.map(date => bookingsByDate[date]);

        return {
            labels,
            datasets: [
                {
                    label: 'Bookings',
                    data,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const formatRoomNightsChartData = () => {
        const roomNightsByDate = bookings.reduce((acc, booking) => {
            const date = new Date(booking.createdAt).toLocaleDateString();
            const numberOfRooms = booking.rooms.reduce((sum, room) => sum + room.numberOfRooms, 0);
            acc[date] = (acc[date] || 0) + booking.totaldays * numberOfRooms;
            return acc;
        }, {});

        const labels = sortDates(Object.keys(roomNightsByDate));
        const data = labels.map(date => roomNightsByDate[date]);

        return {
            labels,
            datasets: [
                {
                    label: 'Room Nights',
                    data,
                    backgroundColor: 'rgba(153, 102, 255, 0.6)',
                    borderColor: 'rgba(153, 102, 255, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const formatRevenueChartData = () => {
        const revenueByDate = bookings.reduce((acc, booking) => {
            const date = new Date(booking.createdAt).toLocaleDateString();
            acc[date] = (acc[date] || 0) + booking.totalamount;
            return acc;
        }, {});

        const labels = sortDates(Object.keys(revenueByDate));
        const data = labels.map(date => revenueByDate[date]);

        return {
            labels,
            datasets: [
                {
                    label: 'Revenue ($)',
                    data,
                    backgroundColor: 'rgba(255, 159, 64, 0.6)',
                    borderColor: 'rgba(255, 159, 64, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const bookingsChartData = formatBookingsChartData();
    const roomNightsChartData = formatRoomNightsChartData();
    const revenueChartData = formatRevenueChartData();

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: { font: { size: 12 } },
            },
            title: { display: true, font: { size: 14 } },
            tooltip: { bodyFont: { size: 12 } },
        },
        scales: {
            x: { ticks: { font: { size: 10 }, maxRotation: 45, minRotation: 45 } },
            y: {
                beginAtZero: true,
                ticks: {
                    font: { size: 10 },
                    callback: function (value) {
                        return this.chart.data.datasets[0].label.includes('Revenue') ? '$' + value.toLocaleString() : value;
                    },
                },
            },
        },
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <div className="p-4 max-w-6xl mx-auto">
                <h1 className="text-xl font-bold mb-3">Booking Analytics</h1>

                <div className="mb-4">
                    <DateRangePicker
                        value={dateRange}
                        onChange={(newValue) => setDateRange(newValue)}
                        renderInput={(startProps, endProps) => (
                            <>
                                <input {...startProps} className="p-2 border rounded-md text-sm" />
                                <span className="mx-2 text-sm">to</span>
                                <input {...endProps} className="p-2 border rounded-md text-sm" />
                            </>
                        )}
                    />
                    <div className="mt-4 bg-white p-3 rounded-lg shadow-sm">
                        <h2 className="text-lg font-semibold mb-2">Booking Summary</h2>
                        <div
                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                            className="text-sm"
                        >
                            <p style={{ margin: 0 }}>
                                Total Bookings:{' '}
                                <span style={{ color: '#008080' }} className="font-medium text-teal-600">
                                    {bookings.length}
                                </span>
                            </p>
                            <p style={{ margin: 0 }}>
                                Total Room Nights:{' '}
                                <span style={{ color: '#9333ea' }} className="font-medium text-purple-600">
                                    {bookings.reduce((sum, booking) => {
                                        const numberOfRooms = booking.rooms.reduce((roomSum, room) => roomSum + room.numberOfRooms, 0);
                                        return sum + booking.totaldays * numberOfRooms;
                                    }, 0)}
                                </span>
                            </p>
                            <p style={{ margin: 0 }}>
                                Total Revenue:{' '}
                                <span style={{ color: '#f97316' }} className="font-medium text-orange-600">
                                    ${bookings.reduce((sum, booking) => sum + booking.totalamount, 0).toLocaleString()}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                {loading ? (
                    <p className="text-center text-gray-500">Loading...</p>
                ) : bookings.length === 0 ? (
                    <p className="text-center text-gray-500">No bookings found for the selected date range.</p>
                ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        <div className="bg-white p-3 rounded-lg shadow-sm">
                            <h2 className="text-md font-semibold mb-2">Bookings Over Time</h2>
                            <div style={{ height: '400px' }}>
                                <Bar
                                    data={bookingsChartData}
                                    options={{ ...chartOptions, plugins: { ...chartOptions.plugins, title: { text: 'Bookings' } } }}
                                />
                            </div>
                        </div>

                        <div className="bg-white p-3 rounded-lg shadow-sm">
                            <h2 className="text-md font-semibold mb-2">Room Nights Over Time</h2>
                            <div style={{ height: '400px' }}>
                                <Bar
                                    data={roomNightsChartData}
                                    options={{ ...chartOptions, plugins: { ...chartOptions.plugins, title: { text: 'Room Nights' } } }}
                                />
                            </div>
                        </div>

                        <div className="bg-white p-3 rounded-lg shadow-sm">
                            <h2 className="text-md font-semibold mb-2">Revenue Over Time</h2>
                            <div style={{ height: '400px' }}>
                                <Bar
                                    data={revenueChartData}
                                    options={{ ...chartOptions, plugins: { ...chartOptions.plugins, title: { text: 'Revenue ($)' } } }}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </LocalizationProvider>
    );
};

export default AnalyticsScreen;