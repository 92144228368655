//back to this
import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import Swal from 'sweetalert2';
import { Outlet, useNavigate } from 'react-router-dom';

const { Header, Content } = Layout;
const { SubMenu } = Menu;

function Adminscreen() {
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAdmin = () => {
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser && currentUser.isAdmin) {
                setIsAdmin(true);
            } else {
                Swal.fire({
                    title: 'Unauthorized',
                    text: 'You are not authorized for this session',
                    icon: 'error',
                    timer: 2000,
                    willClose: () => {
                        window.location.href = '/home';
                    }
                });
            }
        };

        checkAdmin();
    }, []);

    if (!isAdmin) {
        return null;
    }

    return (
        <Layout>
            <Header>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={['dashboard']}
                    className="responsive-menu"
                >
                    <Menu.Item key="dashboard" onClick={() => navigate('/admin/dashboard')}>Dashboard</Menu.Item>
                    <SubMenu key="roomManagement" title="Room & Rate">
                        <Menu.Item key="roomingList" onClick={() => navigate('/admin/roominglist')}>Room Categories</Menu.Item>
                        <Menu.Item key="addRoom" onClick={() => navigate('/admin/addroom')}>Add Room</Menu.Item>
                        <Menu.Item key="ratePlans" onClick={() => navigate('/admin/rateplans')}>Rate Plans</Menu.Item>
                        <Menu.Item key="promotions" onClick={() => navigate('/admin/promotions')}>Promotions</Menu.Item>
                        <Menu.Item key="roomreview" onClick={() => navigate('/admin/roomreview')}>Room Review</Menu.Item>
                    </SubMenu>
                    <Menu.Item key="availability" onClick={() => navigate('/admin/roommanagement')}>Smart Calendar</Menu.Item>
                    <Menu.Item key="channelmanager">Channel Manager</Menu.Item>
                    <SubMenu key="propertySetting" title="Property Setting">
                        <Menu.Item key="generalInfo" onClick={() => navigate('/admin/generalinfo')}>General Info</Menu.Item>
                        <Menu.Item key="policies" onClick={() => navigate('/admin/policies')}>Policies</Menu.Item>
                        <Menu.Item key="hotelrules" onClick={() => navigate('/admin/hotelrules')}>Hotel Rules</Menu.Item>
                        <Menu.Item key="paymentMethods" onClick={() => navigate('/admin/payment-method')}>Payment Methods</Menu.Item>
                        <Menu.Item key="addOnService" onClick={() => navigate('/admin/addonservices')}>Add On Services</Menu.Item>
                        <Menu.Item key="users" onClick={() => navigate('/admin/users')}>Users</Menu.Item>
                        <Menu.Item key="branding" onClick={() => navigate('/admin/branding')}>Branding Design</Menu.Item>
                    </SubMenu>
                    <SubMenu key="reservation" title="Reservation">
                        <Menu.Item key="bookingList" onClick={() => navigate('/admin/bookinglist')}>Bookings</Menu.Item>
                        <Menu.Item key="revealCard" onClick={() => navigate('/admin/retrieve-card-input')}>Retrieve Payment</Menu.Item>
                    </SubMenu>
                    <Menu.Item key="analytics" onClick={() => navigate('/admin/analytics')}>Analytics</Menu.Item>
                </Menu>
            </Header>
            <Content style={{ padding: '20px' }}>
                <Outlet /> {/* This is where the screen content will be injected */}
            </Content>
        </Layout>
    );
}

export default Adminscreen;
