//back to this
import React, { useEffect } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homescreen from './screens/Homescreen';
import Bookingscreen from './screens/Bookingscreen';
import Registerscreen from './screens/Registerscreen';
import LoginScreen from './screens/Loginscreen';
import Footer from './components/Footer';
import Profilescreen from './screens/Profilescreen';
import Adminscreen from './screens/Adminscreen';
import Landingscreen from './screens/Landingscreen';
import RoomManagementScreen from './screens/RoomManagementScreen';
import RatePlanManagementScreen from './screens/RatePlanManagementScreen';
import PoliciesScreen from './screens/PoliciesScreen';
import RetrieveCardInputScreen from './screens/RetrieveCardInputScreen';
import DecryptCardDetailsScreen from './screens/DecryptCardDetailsScreen';
import PaymentMethodScreen from './screens/PaymentMethodScreen';
import PromotionManagementScreen from './screens/PromotionManagementScreen';
import BookingListScreen from './screens/BookingListScreen';
import RoomingListScreen from './screens/RoomingListScreen';
import AddRoomScreen from './screens/AddRoomScreen';
import UsersScreen from './screens/UsersScreen';
import AddOnServiceScreen from './screens/AddOnServiceScreen';
import ReviewManagementScreen from './screens/ReviewManagementScreen';
import GeneralInfoScreen from './screens/GeneralInfoScreen';
import BookingResult from './components/BookingResult';
import ConfirmationScreen from './screens/ConfirmationScreen';
import HotelRulesScreen from './screens/HotelRulesScreen';
import MyBookingScreen from './screens/MyBookingScreen';
import DashboardScreen from './screens/DashboardScreen';
import AnalyticsScreen from './screens/AnalyticsScreen';

import { BookingProvider } from './components/BookingContext';
import { CurrencyProvider } from './components/CurrencyContext';

import './i18n';

import axios from 'axios';
import BrandingScreen from './screens/BrandingScreen';


function App() {
  useEffect(() => {
    const tenantId = window.location.hostname.split('.')[0] || 'defaultTenant'; // Resolve tenant dynamically

    // Fetch tenant-specific meta info
    axios.get('/api/hotelInfo/hotel-info', {
      headers: {
        'X-Tenant-ID': tenantId,
      },
    })
      .then((response) => {
        const hotelInfo = response.data;

        // Update document title
        document.title = hotelInfo.hotelName || 'Welcome to our hotel';

        // Update favicon dynamically
        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon) {
          favicon.href = hotelInfo.favicon || '/favicon.ico'; // Default favicon
        } else {
          const newFavicon = document.createElement('link');
          newFavicon.rel = 'icon';
          newFavicon.href = hotelInfo.favicon || '/favicon.ico';
          document.head.appendChild(newFavicon);
        }

        // Update meta description
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.content = hotelInfo.description || 'Welcome to our hotel';
        }

        // Set Open Graph tags
        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (ogTitle) {
          ogTitle.content = hotelInfo.hotelName || 'Welcome to our hotel';
        }

        const ogDescription = document.querySelector('meta[property="og:description"]');
        if (ogDescription) {
          ogDescription.content = hotelInfo.description || 'Welcome to our hotel';
        }

        const ogImage = document.querySelector('meta[property="og:image"]');
        if (ogImage) {
          ogImage.content = hotelInfo.featureImage || '/default-image.jpg';
        }
      })
      .catch(error => {
        console.error("Error fetching tenant data:", error);
      });
  }, []);

  return (
    <BookingProvider>
      <CurrencyProvider>
        <Router>
          <div className="App">
            <Navbar />
            <Routes>
              <Route path='/home' element={<Homescreen />} />
              <Route path='/booking' element={<Bookingscreen />} />
              <Route path='/register' element={<Registerscreen />} />
              <Route path='/login' element={<LoginScreen />} />
              <Route path='/profile' element={<Profilescreen />} />
              <Route path='/' element={<Landingscreen />} />
              <Route path='/decrypt-card/:bookingId' element={<DecryptCardDetailsScreen />} />
              <Route path="/booking-result" element={<BookingResult />} />
              <Route path="/confirmation" element={<ConfirmationScreen />} />
              <Route path="/mybooking" element={<MyBookingScreen />} />

              {/* Admin routes */}
              <Route path='/admin' element={<Adminscreen />}>
                <Route path='/admin/roommanagement' element={<RoomManagementScreen />} />
                <Route path="/admin/dashboard" element={<DashboardScreen />} />
                <Route path='/admin/rateplans' element={<RatePlanManagementScreen />} />
                <Route path='/admin/policies' element={<PoliciesScreen />} />
                <Route path='/admin/retrieve-card-input' element={<RetrieveCardInputScreen />} />
                <Route path='/admin/roomreview' element={<ReviewManagementScreen />} />
                <Route path='/admin/generalinfo' element={<GeneralInfoScreen />} />
                <Route path="/admin/payment-method" element={<PaymentMethodScreen />} />
                <Route path='/admin/promotions' element={<PromotionManagementScreen />} />
                <Route path='/admin/bookinglist' element={<BookingListScreen />} />
                <Route path='/admin/roominglist' element={<RoomingListScreen />} />
                <Route path='/admin/addroom' element={<AddRoomScreen />} />
                <Route path='/admin/users' element={<UsersScreen />} />
                <Route path='/admin/addonservices' element={<AddOnServiceScreen />} />
                <Route path='/admin/branding' element={<BrandingScreen />} />
                <Route path='/admin/hotelrules' element={<HotelRulesScreen />} />
                <Route path='/admin/analytics' element={<AnalyticsScreen />} />
              </Route>

            </Routes>
            <Footer />
          </div>
        </Router>
      </CurrencyProvider>
    </BookingProvider>
  );
}

export default App;
