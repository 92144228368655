
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import dropin from 'braintree-web-drop-in';  // Import Braintree Drop-in
import './Room2.css'
import ReactFlagsSelect from 'react-flags-select';
import countryCodeToName from '../countryCodeToName.js';
import { Modal } from 'react-bootstrap';

import { CurrencyContext } from '../components/CurrencyContext';

function Bookingscreen() {
    const location = useLocation();
    const { bookingSidebar } = location.state || {};
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const [guestName, setGuestName] = useState('');
    const [guestEmail, setGuestEmail] = useState('');
    const [confirmGuestEmail, setConfirmGuestEmail] = useState('');
    const [guestPhoneNumber, setGuestPhoneNumber] = useState('');
    const [confirmGuestPhoneNumber, setConfirmGuestPhoneNumber] = useState('');
    const [guestNationality, setGuestNationality] = useState('');
    const [specialRequest, setSpecialRequest] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('paypal');
    const [cardNumber, setCardNumber] = useState('');
    const [cardExpiry, setCardExpiry] = useState('');
    const [cardCVC, setCardCVC] = useState('');
    const [extraBedPrice, setExtraBedPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const [braintreeInstance, setBraintreeInstance] = useState(null);
    const [branding, setBranding] = useState('');
    const { currency, convertAmount } = useContext(CurrencyContext);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);

    const navigate = useNavigate();

    const braintreeRef = useRef(null); // Ref for the Braintree Drop-in UI container

    // Refs for guest information
    const guestNameRef = useRef('');
    const guestEmailRef = useRef('');
    const guestPhoneNumberRef = useRef('');
    const guestNationalityRef = useRef('');
    const specialRequestRef = useRef('');

    const formatCurrency = (amount) => {
        const locales = {
            USD: 'en-US',
            VND: 'vi-VN',
            EUR: 'de-DE',
            JPY: 'ja-JP',
            CNY: 'zh-CN',
            KRW: 'ko-KR',
            THB: 'th-TH',
        };

        const currencySymbols = {
            USD: 'USD',
            VND: 'VND',
            EUR: 'EUR',
            JPY: 'JPY',
            CNY: 'CNY',
            KRW: 'KRW',
            THB: 'THB',
        };

        const convertedAmount = convertAmount(amount, currency);

        return new Intl.NumberFormat(locales[currency] || 'vi-VN', {
            style: 'currency',
            currency: currencySymbols[currency] || 'VND',
            maximumFractionDigits: 0,
        }).format(convertedAmount);
    };

    useEffect(() => {
        // Fetch extra bed price and selected payment method from the backend
        const fetchData = async () => {
            try {
                const extraBedPriceResponse = await axios.get('/api/extrabedprice/get');
                setExtraBedPrice(extraBedPriceResponse.data.price);

                const paymentMethodResponse = await axios.get('/api/paymentmethod/getPaymentMethod');
                setPaymentMethod(paymentMethodResponse.data.method);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();

        // Initialize Braintree Drop-in UI
        const initializeBraintree = async () => {
            try {
                const clientTokenResponse = await axios.get('/api/paymentmethod/getBraintreeToken');
                const clientToken = clientTokenResponse.data.clientToken;

                dropin.create({
                    authorization: clientToken,
                    container: braintreeRef.current
                }, (error, instance) => {
                    if (error) {
                        console.error('Error creating Braintree drop-in:', error);
                    } else {
                        setBraintreeInstance(instance);
                    }
                });
            } catch (error) {
                console.error('Error initializing Braintree:', error);
            }
        };

        initializeBraintree();
    }, []);

    useEffect(() => {
        const fetchBranding = async () => {
            try {
                const response = await axios.get('/api/branding/getallbranding');
                setBranding(response.data || { roomNameColor: '#443d80' });
            } catch (error) {
                console.error("Error fetching branding settings:", error);
            }
        };

        fetchBranding();
    }, []);

    const calculateTotalAmount = () => {
        return bookingSidebar?.reduce((acc, plan) => {
            const roomPrice = plan.type !== 'addonservice' ? plan.totalRate * plan.selectedRooms : 0;
            const extraBedCharge = plan.extraBed
                ? Object.values(plan.extraBed || {}).reduce(
                    (sum, isExtraBed) => sum + (isExtraBed ? extraBedPrice * calculateTotalDays() : 0),
                    0
                )
                : 0;
            const addOnServicePrice = plan.type === 'addonservice' ? plan.servicePrice : 0;

            return acc + roomPrice + extraBedCharge + addOnServicePrice;
        }, 0);
    };

    const calculateTotalDays = () => {
        if (bookingSidebar?.length > 0) {
            const fromdate = moment(bookingSidebar[0].fromdate, 'DD-MM-YYYY');
            const todate = moment(bookingSidebar[0].todate, 'DD-MM-YYYY');
            return todate.diff(fromdate, 'days');
        }
        return 0;
    };

    useEffect(() => {
        // Load PayPal SDK
        const loadPayPalSDK = () => {
            // Remove any existing PayPal script to avoid duplication
            const existingScript = document.querySelector('script[src*="paypal.com/sdk/js"]');
            if (existingScript) existingScript.remove();

            // Create the PayPal script dynamically
            const script = document.createElement("script");
            script.src = `https://www.paypal.com/sdk/js?client-id=AfYguK6owoLNLgN2lBJN3m8AST8zeX2swwYqcLHmV9WSy9z-7b7ibTDyVaRBICEa0bcXRtE66i_wvF1N&buyer-country=US&currency=USD&components=buttons&enable-funding=venmo,paylater,card`;
            script.setAttribute("data-sdk-integration-source", "developer-studio");

            script.addEventListener("load", () => {
                console.log("PayPal SDK loaded successfully.");
                if (window.paypal) {
                    setUpPayPalButton();
                } else {
                    Swal.fire('Error', 'PayPal SDK could not be loaded. Please try again later.', 'error');
                }
            });

            script.addEventListener("error", (err) => {
                console.error("Failed to load PayPal SDK:", err);
                Swal.fire('Error', 'PayPal SDK could not be loaded. Please try again later.', 'error');
            });

            document.body.appendChild(script);
        };

        loadPayPalSDK();

        return () => {
            const script = document.querySelector('script[src*="paypal.com/sdk/js"]');
            if (script) script.remove();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePayPalOrder = async (orderId) => {
        // Use refs to get guest information
        const name = guestNameRef.current.trim();
        const email = guestEmailRef.current.trim();
        const phone = guestPhoneNumberRef.current.trim();
        const nationality = guestNationalityRef.current.trim();
        const request = specialRequestRef.current.trim();

        if (!name || !email || !phone || !nationality || !request) {
            Swal.fire('Error', 'All guest information fields are required.', 'error');
            return;
        }
        setLoading(true);
        try {
            const totalamount = calculateTotalAmount();
            const totaldays = calculateTotalDays();

            // Construct booking details for PayPal
            const bookingDetails = {
                bookingSidebar,
                userid: currentUser ? currentUser._id : 'guest',
                totalamount,
                totaldays,
                guestName: name,
                guestEmail: email,
                guestPhoneNumber: phone,
                guestNationality: nationality,
                specialRequest: request,
                paymentMethod: 'paypal',
                orderId, // Include the PayPal order ID
            };

            console.log("PayPal Booking Details sent to backend:", bookingDetails);

            // Send the booking details to the backend
            const response = await axios.post('/api/bookings/capturepaypalorder', bookingDetails);
            Swal.fire({
                title: 'Success',
                text: 'Your reservation was booked successfully',
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(async () => {
                const { data: hotelInfo } = await axios.get('/api/hotelInfo/getallinfo'); // Fetch hotel info
                navigate('/confirmation', { state: { booking: response.data.booking, hotelInfo } });
                if (response.data.confirmationUrl) {
                    window.location.href = response.data.confirmationUrl; // Redirect to confirmation page
                }

            });
        } catch (error) {
            console.error('Error capturing PayPal order:', error);
            Swal.fire('Payment failed', 'Unable to complete the booking.', 'error');
        } finally {
            setLoading(false);
        }
    };

    const setUpPayPalButton = () => {
        if (window.paypal) {
            console.log("Initializing PayPal Buttons...");
            window.paypal.Buttons({
                createOrder: async () => {
                    const name = guestNameRef.current.trim();
                    const email = guestEmailRef.current.trim();
                    const phone = guestPhoneNumberRef.current.trim();
                    const nationality = guestNationalityRef.current.trim();
                    const request = specialRequestRef.current.trim();

                    if (!name || !email || !phone || !nationality || !request) {
                        Swal.fire('Error', 'All guest information fields are required.', 'error');
                        throw new Error('Missing guest information.');
                    }
                    console.log("Creating PayPal order...");

                    const roomPrice = bookingSidebar.reduce((acc, item) => acc + (item.type !== 'addonservice' ? item.totalRate * item.selectedRooms : 0), 0);
                    const addOnServicePrice = bookingSidebar.reduce((acc, item) => acc + (item.type === 'addonservice' ? item.servicePrice : 0), 0);
                    const totalamount = calculateTotalAmount();
                    const totaldays = calculateTotalDays();
                    try {
                        const response = await axios.post('/api/bookings/createPayPalOrder', {
                            totalAmount: totalamount,
                            currency: 'VND',
                            bookingSidebar,
                            totalDays: totaldays,
                            breakdownDetails: { roomPrice, addOnServicePrice },
                        });
                        console.log("Order ID:", response.data.orderId);
                        return response.data.orderId;
                    } catch (error) {
                        console.error("Error creating PayPal order:", error);
                        throw error;
                    }
                },
                onApprove: async (data) => {
                    console.log("PayPal approved:", data);
                    try {
                        await handlePayPalOrder(data.orderID);
                    } catch (error) {
                        console.error("Error completing PayPal booking:", error);
                    }
                },
                onError: (err) => {
                    console.error("PayPal button error:", err);
                },
            }).render("#paypal-button-container").catch(err => {
                console.error("Error rendering PayPal button:", err);
            });
        } else {
            console.error("PayPal SDK not ready.");
        }
    };

    const handleBraintreePayment = async () => {
        try {
            if (!braintreeInstance) throw new Error("Braintree instance not initialized");

            // Request payment method from Braintree Drop-in
            const { nonce } = await braintreeInstance.requestPaymentMethod();
            return nonce; // Return the nonce to use as transaction ID
        } catch (error) {
            console.error('Error processing Braintree payment:', error);
            Swal.fire('Payment failed', 'Braintree payment could not be completed.', 'error');
            throw error;
        }
    };

    useEffect(() => {
        const handleVNPayReturn = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const responseCode = urlParams.get('vnp_ResponseCode');
            const orderId = urlParams.get('vnp_TxnRef'); // Retrieve order ID

            if (responseCode) {
                setLoading(true);
                if (responseCode === '00') {
                    try {
                        console.log(`VNPay Transaction Successful. Order ID: ${orderId}`);
                        const response = await axios.get(`/api/bookings/vnpay/return`, { params: { vnp_TxnRef: orderId } });

                        Swal.fire({
                            title: 'Success',
                            text: 'Your reservation was booked successfully!',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        }).then(() => {
                            const hotelInfo = response.data.hotelInfo; // Assume the backend returns hotel info
                            const booking = response.data.booking; // Assume the backend returns booking details
                            navigate('/confirmation', { state: { booking, hotelInfo } });
                        });
                    } catch (error) {
                        console.error(`Error verifying VNPay payment for Order ID: ${orderId}`, error);
                        Swal.fire('Error', 'Failed to verify payment. Please contact support.', 'error');
                    }
                } else {
                    console.error(`VNPay Transaction Failed. Order ID: ${orderId}`);
                    Swal.fire('Payment Failed', 'VNPay transaction failed. Please try again.', 'error');
                }
                setLoading(false);
            }
        };

        handleVNPayReturn();
    }, [navigate]);


    const handleVNPayPayment = async () => {
        const totalamount = calculateTotalAmount();
        const totaldays = calculateTotalDays();

        const guestDetails = {
            guestName: guestNameRef.current.trim(),
            guestEmail: guestEmailRef.current.trim(),
            guestPhoneNumber: guestPhoneNumberRef.current.trim(),
            guestNationality: guestNationalityRef.current.trim(),
            specialRequest: specialRequestRef.current.trim(),
        };

        if (Object.values(guestDetails).some((field) => !field)) {
            Swal.fire('Error', 'All guest information fields are required.', 'error');
            return;
        }

        console.log('Sending VNPay Payment Request:', {
            totalamount,
            totaldays,
            bookingSidebar,
            guestDetails,
        });

        setLoading(true);
        try {
            // Call backend to get VNPay URL
            const response = await axios.post('/api/bookings/vnpay/create', {
                totalamount,
                totaldays,
                bookingSidebar,
                guestDetails,
            });
            console.log('VNPay Payment URL Response:', response.data);

            if (response.data.paymentUrl) {
                // Redirect to VNPay payment page
                window.location.href = response.data.paymentUrl;
            } else {
                Swal.fire('Error', 'Failed to initiate VNPay payment. Please try again.', 'error');
            }
        } catch (error) {
            console.error('Error initiating VNPay payment:', error);
            Swal.fire('Error', 'Failed to initiate VNPay payment. Please try again.', 'error');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        const handleOnePayReturn = async () => {
            const urlParams = new URLSearchParams(window.location.search);
            const responseCode = urlParams.get('vpc_ResponseCode');
            const orderId = urlParams.get('vpc_MerchTxnRef'); // Retrieve order ID

            if (responseCode) {
                setLoading(true);
                if (responseCode === '0') {
                    try {
                        console.log(`OnePay Transaction Successful. Order ID: ${orderId}`);
                        const response = await axios.get(`/api/bookings/onepay/return`, { params: { vpc_MerchTxnRef: orderId } });

                        Swal.fire({
                            title: 'Success',
                            text: 'Your reservation was booked successfully!',
                            icon: 'success',
                            confirmButtonText: 'OK',
                        }).then(() => {
                            const hotelInfo = response.data.hotelInfo; // Assume the backend returns hotel info
                            const booking = response.data.booking; // Assume the backend returns booking details
                            navigate('/confirmation', { state: { booking, hotelInfo } });
                        });
                    } catch (error) {
                        console.error(`Error verifying OnePay payment for Order ID: ${orderId}`, error);
                        Swal.fire('Error', 'Failed to verify payment. Please contact support.', 'error');
                    }
                } else {
                    console.error(`OnePay Transaction Failed. Order ID: ${orderId}`);
                    Swal.fire('Payment Failed', 'OnePay transaction failed. Please try again.', 'error');
                }
                setLoading(false);
            }
        };

        handleOnePayReturn();
    }, [navigate]);

    const handleOnePayPayment = async () => {
        const totalamount = calculateTotalAmount();
        const totaldays = calculateTotalDays();

        const guestDetails = {
            guestName: guestNameRef.current.trim(),
            guestEmail: guestEmailRef.current.trim(),
            guestPhoneNumber: guestPhoneNumberRef.current.trim(),
            guestNationality: guestNationalityRef.current.trim(),
            specialRequest: specialRequestRef.current.trim(),
        };

        if (Object.values(guestDetails).some((field) => !field)) {
            Swal.fire('Error', 'All guest information fields are required.', 'error');
            return;
        }

        console.log('Sending OnePay Payment Request:', {
            totalamount,
            totaldays,
            bookingSidebar,
            guestDetails,
        });

        setLoading(true);
        try {
            // Call backend to get OnePay URL
            const response = await axios.post('/api/bookings/onepay/create', {
                totalamount,
                totaldays,
                bookingSidebar,
                guestDetails,
            });
            console.log('OnePay Payment URL Response:', response.data);

            if (response.data.paymentUrl) {
                // Redirect to OnePay payment page
                window.location.href = response.data.paymentUrl;
            } else {
                Swal.fire('Error', 'Failed to initiate OnePay payment. Please try again.', 'error');
            }
        } catch (error) {
            console.error('Error initiating OnePay payment:', error);
            Swal.fire('Error', 'Failed to initiate OnePay payment. Please try again.', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleBooking = async () => {
        console.log('Guest Name:', guestName);
        console.log('Guest Email:', guestEmail);
        console.log('Guest Phone Number:', guestPhoneNumber);
        console.log('Guest Nationality:', guestNationality);
        const nationality = guestNationalityRef.current.trim();

        if (!guestName || !guestEmail || !guestPhoneNumber || !guestNationality) {
            Swal.fire('Error', 'All guest information fields are required.', 'error');
            return;
        }
        setLoading(true);
        try {
            let braintreeNonce = null;

            if (paymentMethod === 'vnpay') {
                await handleVNPayPayment();
            }

            if (paymentMethod === 'braintree') {
                braintreeNonce = await handleBraintreePayment(); // Braintree nonce
            }

            const totalamount = calculateTotalAmount();
            const totaldays = calculateTotalDays();

            const bookingDetails = {
                bookingSidebar,
                userid: currentUser ? currentUser._id : 'guest',
                totalamount,
                totaldays,
                guestName,
                guestEmail,
                guestPhoneNumber,
                guestNationality: nationality,
                specialRequest,
                paymentMethod,
                ...(paymentMethod === 'creditCard' && { cardNumber, cardExpiry, cardCVC }),
                ...(paymentMethod === 'braintree' && { braintreeNonce }),
            };

            console.log("Booking Details sent to backend:", bookingDetails); // Log booking details
            const response = await axios.post('/api/bookings/bookroom', bookingDetails);
            const confirmationUrl = response.data.confirmationUrl;
            Swal.fire({
                title: 'Success',
                text: 'Your reservation was booked successfully',
                icon: 'success',
                confirmButtonText: 'OK',
            }).then(async () => {
                const { data: hotelInfo } = await axios.get('/api/hotelInfo/getallinfo');

                // Navigate to ConfirmationScreen with state
                navigate('/confirmation', { state: { booking: response.data.booking, hotelInfo } });

                // Redirect to confirmationUrl (fallback)
                if (confirmationUrl) {
                    window.location.href = confirmationUrl;
                }
            });
        } catch (error) {
            console.error('Booking error:', error);
            Swal.fire('Oops, Something went wrong', 'error');
        } finally {
            setLoading(false);
        }
    };

    const validateEmail = () => {
        if (guestEmail !== confirmGuestEmail) {
            setEmailError('Emails do not match!');
        } else {
            setEmailError('');
        }
    };

    const validatePhoneNumber = () => {
        if (guestPhoneNumber !== confirmGuestPhoneNumber) {
            setPhoneError('Phone numbers do not match!');
        } else {
            setPhoneError('');
        }
    };

    if (!bookingSidebar || bookingSidebar.length === 0) {
        return <div>No booking details available. Please select a room and rate plan.</div>;
    }

    const handleNationalityChange = (code) => {
        setGuestNationality(code); // Update state for UI
        guestNationalityRef.current = countryCodeToName[code]; // Save full country name
    };

    return (
        <div
            className='background'
            style={{
                backgroundImage: `url(${branding.pageBackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: 'fixed',
            }}
        >
            <div className="container">
                <div className="row mt-5 gap-3">
                    <div className="glass-effect2 col-md-6"
                        style={{
                            background: branding.searchBarGradient,
                        }}
                    >
                        <h1 style={{ color: '#FAF3FF' }}>Your Booking Details</h1>
                        {bookingSidebar.length > 0 && (
                            <div className='text-white'>
                                <p><strong>Check In:</strong> {bookingSidebar[0].fromdate}</p>
                                <p><strong>Check Out:</strong> {bookingSidebar[0].todate}</p>
                                <p><strong>Total Nights:</strong> {calculateTotalDays()}</p>
                            </div>
                        )}
                        <ul className="list-group mb-4">
                            {/* Display Rooms */}
                            {bookingSidebar
                                .filter(item => item.type !== 'addonservice')  // Filter out addon services for room rendering
                                .map((item, index) => (
                                    <li className="bs list-group-item d-flex align-items-start" key={index}>

                                        <div>
                                            {item.roomName && item.ratePlanName && (
                                                <>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <h1 style={{ fontSize: '2.5rem', color: '#433d80' }}>
                                                            <strong>{item.roomName}</strong>
                                                        </h1>
                                                        {item.imageurls && item.imageurls[0] && (
                                                            <img
                                                                src={item.imageurls[0]}
                                                                alt={item.roomName}
                                                                style={{
                                                                    width: '80px',
                                                                    height: '80px',
                                                                    objectFit: 'cover',
                                                                    borderRadius: '5px'
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                    <p>
                                                        <strong>Rate Plan: </strong>
                                                        <span style={{ color: branding.paragraphColor }}> {item.ratePlanName}</span>
                                                    </p>
                                                    <p>
                                                        <strong>Meal Plan: </strong>
                                                        <span style={{ color: branding.paragraphColor }}> {item.mealPlan || 'None'}</span>
                                                    </p>
                                                    <p>
                                                        <strong>Number of Rooms Booked: </strong>
                                                        <span style={{ color: branding.paragraphColor }}> {item.selectedRooms}</span>
                                                    </p>
                                                    <p>
                                                        <strong>Adults: </strong>
                                                        <span style={{ color: branding.paragraphColor }}>
                                                            {item.selectedAdults ? Object.values(item.selectedAdults).reduce((a, b) => a + b, 0) : 0}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <strong>Children: </strong>
                                                        <span style={{ color: branding.paragraphColor }}>
                                                            {item.selectedChildren ? Object.values(item.selectedChildren).reduce((a, b) => a + b, 0) : 0}
                                                        </span>
                                                    </p>
                                                    <p>
                                                        <strong>Total Rate: </strong>
                                                        <span style={{ color: branding.paragraphColor }}>
                                                            {formatCurrency(item.totalRate * item.selectedRooms)} {currency}
                                                        </span>
                                                    </p>
                                                    {item.extraBed && Object.values(item.extraBed).some(isExtraBed => isExtraBed) && (
                                                        <p>
                                                            <strong>Extra Bed Price: </strong>
                                                            <span style={{ color: branding.paragraphColor }}>{formatCurrency(extraBedPrice * calculateTotalDays())} {currency} / {calculateTotalDays()} nights</span>
                                                        </p>
                                                    )}
                                                    {item.cancellationPolicy && (
                                                        <p>
                                                            <strong>Cancellation Policy: </strong>
                                                            <span style={{ color: branding.paragraphColor }}> {item.cancellationPolicy}</span>
                                                        </p>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </li>
                                ))}

                            {/* Display Add-On Services */}
                            <h5 className='mt-3 text-white'>Add-On Services</h5>
                            {bookingSidebar
                                .filter(item => item.type === 'addonservice')  // Filter only addon services
                                .map((item, index) => (
                                    <li className="bs list-group-item" key={index}>
                                        <p>
                                            <strong>Service Name:</strong>
                                            <span style={{ color: branding.paragraphColor }}> {item.serviceName}</span>
                                        </p>
                                        <p>
                                            <strong>Description:</strong>
                                            <span style={{ color: branding.paragraphColor }}> {item.serviceDescription}</span>
                                        </p>
                                        <p>
                                            <strong>Service Price:</strong>
                                            <span style={{ color: branding.paragraphColor }}> {formatCurrency(item.servicePrice)} {currency}</span>
                                        </p>
                                    </li>
                                ))}
                        </ul>
                        <h3 className="bs">
                            <strong>Grand Total: <span style={{ color: branding.paragraphColor }}>{formatCurrency(calculateTotalAmount())}</span> {currency} </strong>
                        </h3>
                        <h6 className="bs">
                            <strong>Total You Pay In VND: <span style={{ color: branding.paragraphColor }}>{(calculateTotalAmount())}</span> VND </strong>
                        </h6>

                    </div>

                    <div className="glass-effect2 col-md-5 text-white"
                        style={{
                            background: branding.searchBarGradient,
                        }}
                    >
                        <h1>Guest Information</h1>
                        <form
                            onSubmit={async (e) => {
                                e.preventDefault();

                                // Validation for mismatched email and phone number
                                if (guestEmail !== confirmGuestEmail) {
                                    Swal.fire('Error', 'Email confirmation does not match.', 'error');
                                    return;
                                }

                                if (guestPhoneNumber !== confirmGuestPhoneNumber) {
                                    Swal.fire('Error', 'Phone number confirmation does not match.', 'error');
                                    return;
                                }

                                if (!guestName || !guestEmail || !guestPhoneNumber || !guestNationality) {
                                    Swal.fire('Error', 'All guest information fields are required.', 'error');
                                    return;
                                }

                                if (paymentMethod === 'paypal') {
                                    handlePayPalOrder(); // Trigger PayPal order handling
                                } else if (paymentMethod === 'vnpay') {
                                    await handleVNPayPayment(); // Trigger VNPay payment handling
                                } else if (paymentMethod === 'onepay') {
                                    await handleOnePayPayment(); // Trigger OnePay payment handling
                                } else {
                                    handleBooking(); // Trigger booking for other payment methods
                                }
                            }}
                        >
                            <div className="mb-3">
                                <label htmlFor="guestName" className="form-label">
                                    <strong>Guest Name</strong>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="guestName"
                                    placeholder="Enter your name"
                                    value={guestName}
                                    onChange={(e) => {
                                        setGuestName(e.target.value);
                                        guestNameRef.current = e.target.value;
                                    }}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="guestEmail" className="form-label">
                                    <strong>Email address</strong>
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="guestEmail"
                                    placeholder="Enter your email"
                                    value={guestEmail}
                                    onChange={(e) => {
                                        setGuestEmail(e.target.value);
                                        guestEmailRef.current = e.target.value;
                                    }}
                                    required
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="confirmGuestEmail" className="form-label">
                                    <strong>Confirm Email Address</strong>
                                </label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="confirmGuestEmail"
                                    placeholder="Confirm your email"
                                    value={confirmGuestEmail}
                                    onChange={(e) => setConfirmGuestEmail(e.target.value)}
                                    onBlur={validateEmail}
                                    required
                                />
                                {emailError && <small className="text-danger">{emailError}</small>}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="guestPhoneNumber" className="form-label">
                                    <strong>Phone Number</strong>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="guestPhoneNumber"
                                    placeholder="Enter your phone number"
                                    value={guestPhoneNumber}
                                    onChange={(e) => {
                                        setGuestPhoneNumber(e.target.value);
                                        guestPhoneNumberRef.current = e.target.value;
                                    }}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="confirmGuestPhoneNumber" className="form-label">
                                    <strong>Confirm Phone Number</strong>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="confirmGuestPhoneNumber"
                                    placeholder="Confirm your phone number"
                                    value={confirmGuestPhoneNumber}
                                    onChange={(e) => setConfirmGuestPhoneNumber(e.target.value)}
                                    onBlur={validatePhoneNumber}
                                    required
                                />
                                {phoneError && <small className="text-danger">{phoneError}</small>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="guestNationality" className="form-label">
                                    <strong>Nationality</strong>
                                </label>
                                <ReactFlagsSelect
                                    selected={guestNationality} // Use state value for UI
                                    onSelect={handleNationalityChange} // Use the handler function
                                    searchable
                                    showSelectedLabel={true}
                                    placeholder="Select your nationality"
                                    className="form-control nationality-dropdown"
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="specialRequest" className="form-label">
                                    <strong>Special Requests</strong>
                                </label>
                                <textarea
                                    className="form-control"
                                    id="specialRequest"
                                    rows="3"
                                    placeholder="Any special requests?"
                                    value={specialRequest}
                                    onChange={(e) => {
                                        setSpecialRequest(e.target.value);
                                        specialRequestRef.current = e.target.value;
                                    }}
                                />
                            </div>

                            {/* Payment Method Section */}
                            <h1 className="mt-3">Check-out</h1>
                            {paymentMethod === 'creditCard' && (
                                <>
                                    <div className="mb-3">
                                        <label htmlFor="cardNumber" className="form-label">
                                            <strong>Credit Card Number</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cardNumber"
                                            placeholder="Enter your credit card number"
                                            value={cardNumber}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                                if (input.length <= 16) {
                                                    const formatted = input
                                                        .replace(/(\d{4})/g, '$1 ') // Group into sets of 4 digits
                                                        .trim(); // Remove trailing spaces
                                                    setCardNumber(formatted);

                                                    // Detect card type
                                                    const firstDigit = input.charAt(0);
                                                    let cardType = '';
                                                    if (firstDigit === '4') cardType = 'Visa';
                                                    else if (firstDigit === '5') cardType = 'MasterCard';
                                                    else if (firstDigit === '3') cardType = 'American Express';
                                                    else if (firstDigit === '6') cardType = 'Discover';
                                                    console.log('Detected Card Type:', cardType); // For visual card type detection
                                                }
                                            }}
                                            required
                                        />
                                        <small className="text-muted">Format: XXXX XXXX XXXX XXXX</small>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="cardExpiry" className="form-label">
                                            <strong>Expiry Date (MM/YY)</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cardExpiry"
                                            placeholder="MM/YY"
                                            value={cardExpiry}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                                if (input.length <= 4) {
                                                    const formatted = input.replace(/(\d{2})(\d{1,2})?/, '$1/$2'); // Add slash after MM
                                                    setCardExpiry(formatted);
                                                }
                                            }}
                                            required
                                        />
                                        <small className="text-muted">Format: MM/YY</small>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="cardCVC" className="form-label">
                                            <strong>CVV</strong>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="cardCVC"
                                            placeholder="Enter your CVV"
                                            value={cardCVC}
                                            onChange={(e) => {
                                                const input = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                                                if (input.length <= 4) setCardCVC(input); // CVV max length: 4
                                            }}
                                            required
                                        />
                                        <small className="text-muted">Format: 3 or 4 digits</small>
                                    </div>
                                </>
                            )}

                            {paymentMethod === 'Pay At Hotel' && <p>You will pay upon arrival at the hotel.</p>}
                            {paymentMethod === 'braintree' && (
                                <div className="mb-3">
                                    <div ref={braintreeRef} />
                                </div>
                            )}

                            {/* Terms and Conditions Checkbox */}
                            {paymentMethod !== 'paypal' && (
                                <div className="form-check mt-3">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="termsCheckbox"
                                        checked={isTermsChecked}
                                        onChange={(e) => setIsTermsChecked(e.target.checked)}
                                    />
                                    <label className="form-check-label" htmlFor="termsCheckbox">
                                        I confirm that I am of legal age and agree to the Hotel&nbsp;
                                        <span
                                            style={{
                                                textDecoration: 'underline',
                                                color: 'blue',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => setIsModalVisible(true)}
                                        >
                                            Terms & Conditions
                                        </span>
                                    </label>
                                </div>
                            )}

                            {/* Modal for Terms and Conditions */}
                            <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)} size="lg">
                                <Modal.Header closeButton>
                                    <Modal.Title>Terms and Conditions for Reservations</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h4>TERMS AND CONDITIONS FOR RESERVATIONS</h4>
                                    <p>(Applicable when booking via Syncera Booking Engine)</p>
                                    <ol>
                                        <li>
                                            <strong>Overview:</strong> By using the Syncera Booking Engine to make a reservation, you agree to all the terms and conditions outlined herein. These terms apply to all reservation transactions conducted through the system.
                                        </li>
                                        <li>
                                            <strong>Reservation Confirmation:</strong> Reservations are only confirmed upon receiving a confirmation email from the hotel. All booking requests are subject to room availability at the time of booking.
                                        </li>
                                        <li>
                                            <strong>Pricing Policy:</strong>
                                            <ul>
                                                <li>Room rates displayed on the Syncera Booking Engine include taxes and fees unless otherwise noted.</li>
                                                <li>Promotions or discount codes apply only at the time of booking and cannot be combined with other offers.</li>
                                                <li>Rates are subject to change without prior notice but do not apply to confirmed reservations.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Payment Policy:</strong>
                                            <ul>
                                                <li>Some reservations require immediate payment upon booking; others allow payment at the hotel.</li>
                                                <li>Accepted payment methods (credit card, bank transfer, etc.) will be specified in the system.</li>
                                                <li>If payment transactions fail, the hotel reserves the right to cancel the booking.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Cancellation and Modification Policy:</strong>
                                            <ul>
                                                <li>The cancellation or modification policy will be clearly displayed at the time of booking and in the confirmation email.</li>
                                                <li>Guests must contact the hotel at least [hotel-specific hours/days] before the check-in date to make changes or cancel.</li>
                                                <li>Cancellation or no-show fees will be applied according to the specific booking policy.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Special Requests:</strong> Special requests (e.g., high floor, extra beds, adjacent rooms) will be accommodated to the best of our ability but are not guaranteed.
                                        </li>
                                        <li>
                                            <strong>Privacy Policy:</strong>
                                            <ul>
                                                <li>The hotel is committed to protecting your privacy in accordance with applicable laws.</li>
                                                <li>Personal information will only be used to process your reservation and will not be shared with third parties, except for authorized payment partners.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Transaction Security:</strong>
                                            <ul>
                                                <li>All booking transactions via Syncera Booking Engine are conducted on a secure system (SSL - Secure Sockets Layer).</li>
                                                <li>Payment details, including credit card information, are encrypted to ensure complete security.</li>
                                                <li>Your credit card details may be required to guarantee your reservation. Charges will only be applied per the cancellation or no-show policy specified during booking.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Other Conditions:</strong> All disputes arising will be resolved in accordance with the laws of [the local jurisdiction where the hotel is located]. By using the system, you agree to comply with all these terms and conditions.
                                        </li>
                                        <li>
                                            <strong>Contact Support:</strong> If you have any questions or issues, please contact:
                                            <ul>
                                                <li>Email: [hotel email]</li>
                                                <li>Phone: [hotel phone number]</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Privacy Policy:</strong>
                                            <ul>
                                                <li>
                                                    <strong>Collection of Personal Information:</strong> We collect the following types of information, including but not limited to:
                                                    <ul>
                                                        <li>Full name, address, phone number, and email.</li>
                                                        <li>Payment details (credit card or bank account information).</li>
                                                        <li>Special requests or preferences (if any).</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Use of Personal Information:</strong> Your personal information will be used for the following purposes:
                                                    <ul>
                                                        <li>Confirming and managing reservations.</li>
                                                        <li>Sending promotional information and special offers.</li>
                                                        <li>Collecting feedback to improve service quality.</li>
                                                        <li>Supporting additional services (e.g., visas, airport transfers).</li>
                                                        <li>Inviting participation in surveys or market research.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Sharing of Personal Information:</strong> Your data may be shared with:
                                                    <ul>
                                                        <li>Accommodation partners: To ensure the service meets your requirements.</li>
                                                        <li>Service providers: Including technical, payment, and customer management services.</li>
                                                        <li>Legal authorities: As required by law.</li>
                                                        <li>International transfers: Data may be transferred to other countries, subject to data protection regulations.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Use of Cookies:</strong>
                                                    <ul>
                                                        <li><strong>Purpose:</strong> Cookies help enhance user experience and monitor website activity.</li>
                                                        <li><strong>Management:</strong> You can disable cookies via your browser settings, but some functionalities may be affected.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Data Security:</strong> Personal data is protected through technical and organizational measures to prevent unauthorized access or data loss.
                                                </li>
                                                <li>
                                                    <strong>Data Retention:</strong> Data will be retained as long as necessary to:
                                                    <ul>
                                                        <li>Provide services.</li>
                                                        <li>Fulfill legal obligations.</li>
                                                        <li>Protect the hotel's business interests.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Your Rights:</strong> You have the right to:
                                                    <ul>
                                                        <li>Access, amend, or request deletion of your personal data.</li>
                                                        <li>Restrict data processing or opt-out of promotional communications.</li>
                                                        <li>Submit your request via email: privacy@syncera.com.</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <strong>Exercising Your Rights:</strong> Your requests will be processed per applicable laws and regulations.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Updates to Terms and Policies:</strong> The terms and policies may be updated. Changes will be announced on the website with the new effective date.
                                        </li>
                                    </ol>

                                </Modal.Body>
                                <Modal.Footer>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        onClick={() => setIsModalVisible(false)}
                                    >
                                        Close
                                    </button>
                                </Modal.Footer>
                            </Modal>

                            {/* Show PayPal button for PayPal payment */}
                            {paymentMethod === 'paypal' ? (
                                <div id="paypal-button-container" style={{ marginBottom: '20px' }}></div>
                            ) : (
                                <button
                                    type="submit"
                                    className="btn btn-primary w-100 mb-5 bs"
                                    disabled={!isTermsChecked || loading}
                                    style={{ marginBottom: '15px', marginTop: '1rem', color: '#4d4692', fontWeight: '800' }}
                                >
                                    {loading ? 'Processing...' : 'BOOK NOW'}
                                </button>
                            )}

                            {/* Security Verification Section */}
                            <div className="security-verification text-center">
                                <p style={{ marginBottom: '10px' }}>Your payment is secured with:</p>
                                <div className="d-flex justify-content-center align-items-center gap-3">
                                    <div>
                                        <img
                                            src="/pngwing.com.png" // Replace with PCI DSS logo URL
                                            alt="PCI DSS Compliant"
                                            style={{ width: '120px' }}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            src="/clipart2051305.png" // Replace with SSL logo URL
                                            alt="SSL Secure"
                                            style={{ width: '120px' }}
                                        />
                                    </div>
                                    <div>
                                        <img
                                            src="sha256-encrypt-decrypt.svg" // Replace with SHA-256 logo URL
                                            alt="SHA-256 Encrypted"
                                            style={{ width: '42px' }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Bookingscreen;

