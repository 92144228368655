import React, { useEffect, useState } from 'react';
import { Layout, Card, Row, Col, List, Avatar } from 'antd';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';

// Make sure countryCodeToName is something like:
// { US: "United States", VN: "Vietnam", CA: "Canada", ... }
import countryCodeToName from '../countryCodeToName';

const { Content } = Layout;

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ChartDataLabels);

function DashboardScreen() {
    const [todayBookingCount, setTodayBookingCount] = useState(0);
    const [todayRevenue, setTodayRevenue] = useState(0);
    const [chartData, setChartData] = useState(null);
    const [todayTotalDays, setTodayTotalDays] = useState(0);
    const [todayCancelledBookings, setTodayCancelledBookings] = useState(0);
    const [todayADR, setTodayADR] = useState(0);
    const [monthYear, setMonthYear] = useState('');
    const [arrivalList, setArrivalList] = useState([]);
    const [overbookedRooms, setOverbookedRooms] = useState([]);
    const [todayStayRevenue, setTodayStayRevenue] = useState(0);
    const [todayStayRoomNight, setTodayStayRoomNight] = useState(0);
    const [todayStayADR, setTodayStayADR] = useState(0);
    const [roomNightChartData, setRoomNightChartData] = useState(null);



    useEffect(() => {
        const fetchTodayBookings = async () => {
            try {
                const response = await axios.get('/api/bookings/booktoday');
                setTodayBookingCount(response.data.count);
            } catch (error) {
                console.error("Error fetching today's bookings:", error);
            }
        };

        const fetchTodayRevenue = async () => {
            try {
                const response = await axios.get('/api/bookings/chart/todayrevenue');
                setTodayRevenue(response.data.totalRevenue);
            } catch (error) {
                console.error("Error fetching today's revenue:", error);
            }
        };

        const fetchTodayCancelledBookings = async () => {
            try {
                const response = await axios.get('/api/bookings/chart/todaycancelled');
                setTodayCancelledBookings(response.data.cancelledCount);
            } catch (error) {
                console.error("Error fetching today's canceled bookings:", error);
            }
        };

        const fetchArrivalList = async () => {
            try {
                // We want "DD-MM-YYYY"
                const today = moment().format('DD-MM-YYYY');
                const response = await axios.get(`/api/bookings/arrival/${today}`);
                setArrivalList(response.data);
            } catch (error) {
                console.error("Error fetching arrival list:", error);
            }
        };

        const calculateADR = () => {
            if (todayRevenue > 0 && todayTotalDays > 0) {
                setTodayADR((todayRevenue / todayTotalDays).toFixed(2));
            } else {
                setTodayADR(0);
            }
        };

        fetchTodayBookings();
        fetchTodayRevenue();
        fetchTodayCancelledBookings();
        fetchArrivalList();
        calculateADR();
    }, [todayRevenue, todayTotalDays]);

    useEffect(() => {
        const fetchTodayBookedRoomNight = async () => {
            try {
                const response = await axios.get('/api/bookings/chart/todayroomnight');
                setTodayTotalDays(response.data.totalBookedRoomNights);
            } catch (error) {
                console.error("Error fetching today's booked room night:", error);
            }
        };

        fetchTodayBookedRoomNight();
    }, []);

    useEffect(() => {
        const fetchTodayStayRevenue = async () => {
            try {
                const response = await axios.get('/api/bookings/stay/todayrevenue');
                setTodayStayRevenue(response.data.totalStayRevenue);
            } catch (error) {
                console.error("Error fetching today's stay revenue:", error);
            }
        };

        fetchTodayStayRevenue();
    }, []);

    useEffect(() => {
        const fetchTodayStayRoomNight = async () => {
            try {
                const response = await axios.get('/api/bookings/stay/todayroomnight');
                setTodayStayRoomNight(response.data.totalStayRoomNights);
            } catch (error) {
                console.error("Error fetching today's stay room night:", error);
            }
        };

        fetchTodayStayRoomNight();
    }, []);

    useEffect(() => {
        if (todayStayRevenue > 0 && todayStayRoomNight > 0) {
            setTodayStayADR((todayStayRevenue / todayStayRoomNight).toFixed(2));
        } else {
            setTodayStayADR(0);
        }
    }, [todayStayRevenue, todayStayRoomNight]);

    useEffect(() => {
        const fetchOverbookedStatus = async () => {
            try {
                const response = await axios.get('/api/rooms/overbooked-status'); // Ensure the API endpoint is correct
                setOverbookedRooms(response.data);
            } catch (error) {
                console.error('Error fetching overbooked status:', error);
            }
        };

        fetchOverbookedStatus();
    }, []);

    const getAvailabilityColor = (availability) => {
        if (availability === 0) return '#ff4d4f'; // Red for 0 availability
        if (availability >= 1 && availability <= 3) return '#ffec3d'; // Yellow for 1-3 availability
        return '#73d13d'; // Green for availability > 3
    };


    useEffect(() => {
        const fetchChartData = async () => {
            try {
                const response = await axios.get('/api/bookings/chart/month');
                const { dates, counts, month, year } = response.data;

                setMonthYear(`${month} ${year}`);
                setChartData({
                    labels: dates,
                    datasets: [
                        {
                            label: 'Bookings',
                            data: counts,
                            backgroundColor: 'rgba(63, 81, 181, 0.6)',
                            borderColor: 'rgba(63, 81, 181, 1)',
                            borderWidth: 1,
                        },
                    ],
                });
            } catch (error) {
                console.error('Error fetching booking chart data:', error);
            }
        };

        fetchChartData();
    }, []);

    useEffect(() => {
        const fetchRoomNightChartData = async () => {
            try {
                const response = await axios.get('/api/bookings/chart/roomnight');
                const { dates, roomNights, month, year } = response.data;

                // Set month and year for the title
                setMonthYear(`${month} ${year}`);

                // Create a new array of all dates in the current month (even if no room nights are available)
                const allDates = [];
                const allRoomNights = [];

                // Ensure all dates of the month are included
                const daysInMonth = moment().daysInMonth();
                for (let i = 1; i <= daysInMonth; i++) {
                    const date = moment().date(i).format('YYYY-MM-DD');
                    allDates.push(date);

                    // Check if the room nights data exists for the date
                    const index = dates.indexOf(date);
                    allRoomNights.push(index !== -1 ? roomNights[index] : 0); // Set to 0 if no data exists
                }

                // Set the room night chart data with the adjusted arrays
                setRoomNightChartData({
                    labels: allDates,
                    datasets: [
                        {
                            label: 'Room Nights',
                            data: allRoomNights,
                            backgroundColor: 'rgba(255, 99, 132, 0.6)', // Color for the chart
                            borderColor: 'rgba(255, 99, 132, 1)',
                            borderWidth: 1,
                        },
                    ],
                });
            } catch (error) {
                console.error('Error fetching room night chart data:', error);
            }
        };

        fetchRoomNightChartData();
    }, []);


    const renderArrivalList = () => {
        return (
            <List
                dataSource={arrivalList}
                renderItem={(item) => {
                    // Find country code by comparing the string
                    const countryCode = Object.keys(countryCodeToName).find(
                        (code) => countryCodeToName[code] === item.guestNationality
                    );

                    return (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Avatar
                                        src={
                                            countryCode
                                                ? `https://flagcdn.com/h240/${countryCode.toLowerCase()}.png`
                                                : undefined
                                        }
                                        alt={item.guestNationality}
                                    />
                                }
                                title={`Booking ID: ${item.bookingId}`}
                                description={`${item.guestName} (${item.guestNationality})`}
                            />
                            <div>
                                Rooms: <b>{item.totalRooms || 0}</b>, &nbsp;
                                Adults: <b>{item.adults || 0}</b>, &nbsp;
                                Children: <b>{item.children || 0}</b>
                            </div>
                        </List.Item>
                    );
                }}
            />
        );
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Content style={{ padding: '20px' }}>
                <h1 style={{ textAlign: 'Left' }}>Admin Dashboard</h1>

                <Row gutter={[16, 16]} justify="center">
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card title="New Bookings Today" bordered style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '36px', fontWeight: '1000', color: '#433d80' }}>
                                {todayBookingCount}
                            </p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card title="Today's Booked Room Night" bordered style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '36px', fontWeight: '1000', color: '#433d80' }}>
                                {todayTotalDays}
                            </p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card title="Today's Booked Revenue" bordered style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '36px', fontWeight: 'bold', color: '#008000' }}>
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'VND',
                                }).format(todayRevenue)}
                            </p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card title="Today's Booked ADR (Average Daily Rate)" bordered style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '36px', fontWeight: 'bold', color: '#1e90ff' }}>
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'VND',
                                }).format(todayADR)}
                            </p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card title="Today's Cancelled Bookings" bordered style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '36px', fontWeight: '1000', color: '#ff0000' }}>
                                {todayCancelledBookings}
                            </p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card title="Stay Room Night Today" bordered style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '36px', fontWeight: '1000', color: '#433d80' }}>
                                {todayStayRoomNight}
                            </p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card title="Today Stay Revenue" bordered style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '36px', fontWeight: 'bold', color: '#008000' }}>
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'VND',
                                }).format(todayStayRevenue)}
                            </p>
                        </Card>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6}>
                        <Card title="Today's Stay ADR (Average Daily Rate)" bordered style={{ textAlign: 'center' }}>
                            <p style={{ fontSize: '36px', fontWeight: 'bold', color: '#1e90ff' }}>
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'VND',
                                }).format(todayStayADR)}
                            </p>
                        </Card>
                    </Col>
                </Row>


                <Row gutter={[16, 16]} justify="center" style={{ marginTop: '20px' }}>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card title="Today's Arrival List" bordered style={{ minHeight: '400px' }}>
                            {arrivalList.length > 0 ? (
                                renderArrivalList()
                            ) : (
                                <p>No arrivals scheduled for today.</p>
                            )}
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card title="Availability Alert Today" bordered>
                            {overbookedRooms.length > 0 ? (
                                <List
                                    dataSource={overbookedRooms}
                                    renderItem={(room) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <span style={{ fontSize: '20px', fontWeight: 'bold' }}>
                                                        {room.name}
                                                    </span> // Room name styling
                                                }
                                                description={
                                                    <span>
                                                        Availability:
                                                        <span style={{ fontSize: '24px', fontWeight: 'bold', marginLeft: '8px' }}>
                                                            {room.availability}
                                                        </span>
                                                    </span>
                                                }
                                            />
                                            <div
                                                style={{
                                                    backgroundColor: getAvailabilityColor(room.availability),
                                                    width: '20px',
                                                    height: '20px',
                                                    borderRadius: '50%',
                                                }}
                                            ></div>
                                        </List.Item>
                                    )}
                                />
                            ) : (
                                <p>No availability data for today.</p>
                            )}
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card style={{ margin: '20px auto', textAlign: 'center' }} bordered>
                            <h2 style={{ marginBottom: '20px' }}>{monthYear} (MTD Booking Picked Up)</h2>
                            {chartData ? (
                                <Bar
                                    data={chartData}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: { display: false },
                                            tooltip: {
                                                callbacks: {
                                                    label: (context) => `Bookings: ${context.raw}`,
                                                },
                                            },
                                            datalabels: {
                                                display: true,
                                                color: 'black',
                                                font: { size: 12, weight: 'bold' },
                                                anchor: 'end',
                                                align: 'end',
                                                formatter: (value) => (value > 0 ? value : ''),
                                            },
                                        },
                                        scales: {
                                            x: {
                                                title: { display: true, text: 'Date' },
                                                ticks: {
                                                    callback: (value, index) => {
                                                        // chartData.labels is "YYYY-MM-DD" (assuming your API returns that)
                                                        // We want to display just day of month
                                                        const date = chartData.labels[index];
                                                        return date.split('-')[2];
                                                    },
                                                },
                                            },
                                            y: {
                                                title: { display: true, text: 'Number of Bookings' },
                                                beginAtZero: true,
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <p>Loading booking chart...</p>
                            )}
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Card style={{ margin: '20px auto', textAlign: 'center' }} bordered>
                            <h2 style={{ marginBottom: '20px' }}>
                                {monthYear} (MTD Room Night Picked Up)
                            </h2>
                            {roomNightChartData ? (
                                <Bar
                                    data={roomNightChartData}
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: { display: false },
                                            tooltip: {
                                                callbacks: {
                                                    label: (context) => `Room Nights: ${context.raw}`,
                                                },
                                            },
                                            datalabels: {
                                                display: true,
                                                color: 'black',
                                                font: { size: 12, weight: 'bold' },
                                                anchor: 'end',
                                                align: 'end',
                                                formatter: (value) => (value > 0 ? value : ''),
                                            },
                                        },
                                        scales: {
                                            x: {
                                                title: { display: true, text: 'Date' },
                                                ticks: {
                                                    callback: (value, index) => {
                                                        const date = roomNightChartData.labels[index];
                                                        return date.split('-')[2]; // Show just day of the month
                                                    },
                                                },
                                            },
                                            y: {
                                                title: { display: true, text: 'Room Nights' },
                                                beginAtZero: true,
                                            },
                                        },
                                    }}
                                />
                            ) : (
                                <p>Loading room night chart...</p>
                            )}
                        </Card>
                    </Col>

                </Row>
            </Content>
        </Layout>
    );
}

export default DashboardScreen;
